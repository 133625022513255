import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Icon from "../common/Icon";
import { logoutUser } from "../../actions/authActions";
import {
  clearCurrentProfile,
  getCurrentProfile,
} from "../../actions/meActions";
import isEmpty from "../../validation/isEmpty";

class Navbar extends Component {
  componentDidMount() {
    this.props.getCurrentProfile();
  }

  onLogoutClick(e) {
    e.preventDefault();
    this.props.clearCurrentProfile();
    this.props.logoutUser();
    window.location.href = "/";
  }

  render() {
    const { isAuthenticated } = this.props.auth;
    const { me } = this.props.me;

    var profileLink = {};
    if (!isEmpty(me)) {
      profileLink = (
        <Link className="nav-link" to={`/profiles/${me.username}`}>
          <i className="fas fa-user-circle fa-lg text-primary mx-2 d-md-none"></i>
          <i className="fas fa-user-circle text-primary mx-2 d-none d-md-inline"></i>
          <small className="text-muted ml-1 d-none d-md-inline">Profile</small>
        </Link>
      );
    } else {
      profileLink = (
        <Link className="nav-link" to={`/update-profile/`}>
          <i className="fas fa-user-circle fa-lg text-primary mx-2 d-md-none"></i>
          <i className="fas fa-user-circle text-primary mx-2 d-none d-md-inline"></i>
          <small className="text-muted ml-1 d-none d-md-inline">Profile</small>
        </Link>
      );
    }

    const authLinks = (
      <ul className="navbar-nav ml-auto">
        {/* {!isEmpty(this.props.location) && this.props.location.pathname === "/" && (
          <li className="nav-item">
            <a
              className="nav-link"
              data-toggle="collapse"
              data-target="#search"
              href="#"
            >
              <i className="fas fa-search fa-lg text-muted mx-3 d-md-none"></i>
              <i className="fas fa-search text-muted mx-2 d-none d-md-inline"></i>
              <small className="text-muted ml-1 d-none d-md-inline">
                Search
              </small>
            </a>
          </li>
        )} */}
        <li className="nav-item">{profileLink}</li>
        <li className="nav-item">
          <Link className="nav-link" to="/">
            <i className="fa fa-carrot fa-lg text-warning mx-2 d-md-none"></i>
            <i className="fa fa-carrot text-warning mx-2 d-none d-md-inline"></i>
            <small className="text-muted ml-1 d-none d-md-inline">
              Recipes
            </small>
          </Link>
        </li>
        <li className="nav-item">
          <a
            href="#"
            onClick={this.onLogoutClick.bind(this)}
            className="nav-link"
          >
            <i className="fas fa-sign-out-alt fa-lg text-muted mx-2 d-md-none"></i>
            <i className="fas fa-sign-out-alt text-muted mx-2 d-none d-md-inline"></i>

            <small className="text-muted ml-1 d-none d-md-inline">
              Sign out
            </small>
          </a>
        </li>
      </ul>
    );

    const guestLinks = (
      <ul className="navbar-nav ml-auto">
        {!isEmpty(this.props.location) && this.props.location.pathname === "/" && (
          <li className="nav-item">
            <Link className="nav-link" to="/landing">
              <i className="fas fa-lightbulb fa-lg text-warning mx-2 d-md-none"></i>
              <i className="fas fa-lightbulb text-warning mx-2 d-none d-md-inline"></i>

              <small className="text-muted ml-1 d-none d-md-inline">
                Learn more
              </small>
            </Link>
          </li>
        )}
        {!isEmpty(this.props.location) &&
          this.props.location.pathname === "/landing" && (
            <li className="nav-item">
              <Link className="nav-link" to="/register">
                <i className="fas fa-user-plus fa-lg text-primary mx-2 d-md-none"></i>
                <i className="fas fa-user-plus text-primary mx-2 d-none d-md-inline"></i>

                <small className="text-muted ml-1 d-none d-md-inline">
                  Sign up
                </small>
              </Link>
            </li>
          )}
        <li className="nav-item">
          <Link className="nav-link" to="/login">
            <i className="fas fa-user-circle fa-lg text-primary mx-2 d-md-none"></i>
            <i className="fas fa-user-circle text-primary mx-2 d-none d-md-inline"></i>

            <small className="text-muted ml-1 d-none d-md-inline">
              Sign in
            </small>
          </Link>
        </li>
      </ul>
    );

    return (
      <nav className="navbar sticky-top navbar-expand navbar-light bg-light">
        {/* <Link className="navbar-brand site-title h1" to="/">
          <Icon className="p-0"></Icon>
          The Social Spoon
        </Link> */}

        <Link className="navbar-brand" to="/">
          <Icon className="p-0 d-inline" width="35" height="35"></Icon>
          <div className="site-title d-md-inline ml-2 d-none">
            The Social Spoon
          </div>
        </Link>

        <div className="navbar-collapse">
          {isAuthenticated ? authLinks : guestLinks}
        </div>
        {/* </div> */}
      </nav>
    );
  }
}
Navbar.propTypes = {
  logoutUser: PropTypes.func.isRequired,
  getCurrentProfile: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  me: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => ({
  auth: state.auth,
  me: state.me,
});

export default connect(mapStateToProps, {
  logoutUser,
  clearCurrentProfile,
  getCurrentProfile,
})(withRouter(Navbar));
