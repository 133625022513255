import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Spinner from "../common/Spinner";
import RecipeFeed from "./RecipeFeed";
import InfiniteScroll from "react-infinite-scroll-component";
import { getRecipes } from "../../actions/recipesActions";

import { Typeahead } from "react-bootstrap-typeahead";
import isEmpty from "../../validation/isEmpty";
import "react-bootstrap-typeahead/css/Typeahead.min.css";

class Recipes extends Component {
  constructor(props) {
    super(props);

    this.state = {
      togglequick: false,
      togglefew: false,
      togglesmall: false,
      search: "",
      filteredRecipes: [],
      page: 1,
      docs: [],
      products: [],
      errors: {},
    };

    this.fetchMoreData = this.fetchMoreData.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onSearch = this.onSearch.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  onChange(e) {
    const value =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;

    this.setState({
      [e.target.name]: value,
    });
  }

  onChangeTypeAhead(e) {
    this.setState({
      ingredient: e.name,
    });
  }

  componentDidMount() {
    this.props.getRecipes(this.state.page);
    window.scrollTo(0, 0);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    // if (!isEmpty(nextProps.errors)) {
    //   this.setState({ errors: nextProps.errors });
    // }

    if (
      !isEmpty(nextProps.recipes) &&
      !isEmpty(nextProps.recipes.recipes) &&
      !isEmpty(nextProps.recipes.recipes.docs)
    ) {
      const recipes = nextProps.recipes.recipes;

      //if(Number(recipes.page) != Number(this.state.page))
      //{

      if (Number(recipes.page) > Number(this.state.page)) {
        var joinedArrays = this.state.docs.concat(recipes.docs);

        this.setState({
          recipes: !isEmpty(recipes) ? recipes : this.state.recipes,
          docs: !isEmpty(recipes.docs) ? [...joinedArrays] : this.state.docs,
          products: !isEmpty(recipes.products)
            ? [...joinedArrays]
            : this.state.products,
          page: Number(recipes.page),
        });
      } else if (isEmpty(this.state.recipes)) {
        this.setState({
          recipes: !isEmpty(recipes) ? recipes : this.state.recipes,
          docs: !isEmpty(recipes.docs) ? recipes.docs : this.state.docs,
          products: !isEmpty(recipes.products)
            ? recipes.products
            : this.state.products,
          page: Number(recipes.page),
        });
      }

      // }
      // else{

      //   var joinedArrays = recipes.docs;
      //   this.setState({
      //     recipes: !isEmpty(recipes) ? recipes : this.state.recipes,
      //     docs: !isEmpty(recipes.docs) ? [...joinedArrays] : this.state.docs,
      //     page: Number(recipes.page)
      // })
    }
  }

  fetchMoreData = () => {
    this.props.getRecipes(Number(this.state.recipes.page) + Number(1));
    //this.setState({page: Number(this.state.page) + Number(1)});
  };

  onSubmit(e) {
    //e.preventDefault();
    //this.props.history.push("/");
    //window.location.href = "/search";
  }

  onSearch(e) {
    // e.preventDefault();
    // const value =
    //   e.target.type === "checkbox" ? e.target.checked : e.target.value;
    // // this.setState({
    // //   [e.target.name]: value,
    // // });
    // var resultFilter = [];
    // let textFilter = this.state.docs.filter((recipe) => {
    //   return (
    //     recipe.name.toLowerCase().includes(e.target.value.toLowerCase()) ||
    //     recipe.author.name
    //       .toLowerCase()
    //       .includes(e.target.value.toLowerCase()) ||
    //     recipe.ingredients.filter((ingredient) => {
    //       return ingredient.ingredient.name
    //         .toLowerCase()
    //         .includes(e.target.value.toLowerCase());
    //     }).length > 0
    //   );
    // });
    // if (textFilter.length > 0) {
    //   resultFilter = resultFilter.concat(textFilter);
    // }
    // // var fewFilter = [];
    // // if (e.target.name === "togglefew" && e.target.checked === true) {
    // //   fewFilter = textFilter.filter((recipe) => {
    // //     return recipe.ingredients.length <= 5;
    // //   });
    // // }
    // // if (fewFilter.length > 0) {
    // //   resultFilter = resultFilter.concat(fewFilter);
    // // }
    // // var smallFilter = [];
    // // if (e.target.name === "togglesmall" && e.target.checked === true) {
    // //   smallFilter = fewFilter.filter((recipe) => {
    // //     return parseInt(recipe.serves) <= 2;
    // //   });
    // // }
    // // if (smallFilter.length > 0) {
    // //   resultFilter = resultFilter.concat(smallFilter);
    // // }
    // //dedup, stuck here too tired..
    // // var results = [];
    // // for(var i =0; i < resultFilter.length; i++)
    // // {
    // //   results[i]["name"]
    // // }
    // this.setState({
    //   filteredRecipes: [...resultFilter],
    //   [e.target.name]: value,
    // });
  }

  render() {
    const { recipes } = this.state;

    if (isEmpty(recipes)) {
      return (
        <div className="row">
          <div className="col-md-12 align-middle">
            <Spinner />
          </div>
        </div>
      );
    }

    let searchContent = (
      <div className="collapse" id="search">
        <div className="row justify-content-center m-2">
          <div className="col-md-10">
            <form className="">
              <div className="input-group">
                <div className="input-group-prepend">
                  <span className="input-group-text">
                    <i className="fas fa-search text-muted"></i>
                  </span>
                </div>
                <Typeahead
                  id="basic-tah"
                  placeholder="Search for recipes, ingredients, or authors"
                  options={recipes.docs}
                  labelKey="name"
                  onChange={this.onChange}
                ></Typeahead>
                {/* <input
                  className="form-control text-muted"
                  type="text"
                  name="search"
                  placeholder="Search for recipes, ingredients, or authors"
                  aria-label="Search"
                  onChange={this.onSearch}
                ></input> */}
              </div>

              {/* <div className="custom-control custom-switch">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id="customSwitch1"
                />
                <label className="custom-control-label" for="customSwitch1">
                  30 minutes or less
                </label>
              </div> */}
              {/* <div className="custom-control custom-switch">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id="customSwitch1"
                />
                <label className="custom-control-label" for="customSwitch1">
                  5 ingredients or less
                </label>
              </div> */}
              {/* <div className="custom-control custom-switch">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id="customSwitch1"
                />
                <label className="custom-control-label" for="customSwitch1">
                  serves 2 or less
                </label>
              </div> */}
            </form>
          </div>
        </div>
      </div>
    );
    let recipeContent = null;

    if (isEmpty(this.state.recipes) || isEmpty(this.state.recipes.docs)) {
      recipeContent = (
        <div className="row">
          <div className="col-md-12  align-middle">
            <Spinner />
          </div>
        </div>
      );
    } else if (this.state.filteredRecipes.length === 0) {
      recipeContent = (
        <InfiniteScroll
          dataLength={this.state.docs.length}
          hasMore={
            this.state.recipes.page < this.state.recipes.pages ? true : false
          }
          next={this.fetchMoreData}
        >
          <div className="card-columns">
            <RecipeFeed
              recipes={this.state.docs}
              products={this.state.products}
            />
          </div>
        </InfiniteScroll>
      );
    }

    return (
      <>
        {searchContent}
        {recipeContent}
      </>
    );
  }
}

Recipes.propTypes = {
  getRecipes: PropTypes.func.isRequired,
  recipes: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  recipes: state.recipes,
  errors: state.errors,
});

export default connect(mapStateToProps, { getRecipes })(withRouter(Recipes));
