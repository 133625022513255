import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";
import isEmpty from "../../validation/isEmpty";

class RecipeListing extends Component {
  render() {
    let recipesList;

    if (!isEmpty(this.props.recipes)) {
      recipesList = this.props.recipes.map((recipe, index) => (
        <tr key={recipe._id}>
          <td>
            <Link to={`/recipe/${recipe.url}`}>
              {recipe.name} {recipe.deleted ? " (deleted)" : ""}
              {recipe.private ? (
                <i className="far fa-eye-slash text-warning ml-2" />
              ) : (
                ""
              )}
            </Link>
          </td>
          <td>
            <button type="button" className="btn btn-light btn-sm float-right">
              <Link to={`/update-recipe/${recipe.url}`}>
                <i className="fas fa-edit text-primary" />
              </Link>
            </button>

            {this.props.title !== "Meal Prep" && (
              <button
                type="button"
                onClick={(param) => this.props.onAddMealPrep(recipe._id)}
                className="btn btn-light btn-sm float-right ml-1"
              >
                <i className="fas fa-utensils text-primary" />
              </button>
            )}

            {this.props.title === "Meal Prep" && (
              <button
                type="button"
                onClick={(param) => this.props.onRemoveMealPrep(recipe._id)}
                className="btn btn-light btn-sm float-right ml-1"
              >
                <i className="fas fa-trash-alt text-primary" />
              </button>
            )}
          </td>
        </tr>
      ));
    } else {
      recipesList = (
        <tr>
          <td>Nothing is here, yet!</td>
          <td />
          <td />
        </tr>
      );
    }

    return (
      <div>
        <h5 className="mb-4">{this.props.title}</h5>
        <table className="table table-sm table-hover">
          <thead>
            <tr />
            <tr />
            <tr />
          </thead>
          <tbody>{recipesList}</tbody>
        </table>
      </div>
    );
  }
}

export default connect(null)(withRouter(RecipeListing));
