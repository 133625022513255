import { GET_RECIPES, RECIPES_LOADING } from "../actions/types";

const initalState = {
  recipes: null,
  loading: false,
};

export default function (state = initalState, action) {
  switch (action.type) {
    case RECIPES_LOADING:
      return { ...state, loading: true };

    case GET_RECIPES:
      return {
        ...state,
        recipes: action.payload,
        loading: false,
      };

    default:
      return state;
  }
}
