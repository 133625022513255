import axios from "axios";

import {
  UPDATE_RECIPE_FORM,
  GET_RECIPE_FORM,
  UPLOAD_RECIPE_IMAGE_FORM,
  RECIPE_FORM_LOADING,
  GET_ERRORS,
} from "./types";

export const updateRecipe = (recipeData, history) => (dispatch) => {
  //todo: after recipe is created, take the user to the view recipe screen for that recipe.
  axios
    .post("/api/recipes", recipeData)
    .then((res) => dispatch({ type: UPDATE_RECIPE_FORM, payload: res.data }))
    .then((res) => {
      
      history.push(`/recipe/${res.payload.url}`)})
    .catch((err) => dispatch({ type: GET_ERRORS, payload: err }));
};

export const uploadRecipeImage = (recipeData, history) => (dispatch) => {
  dispatch(setRecipeLoading());
  axios
    .post("/api/images", recipeData)
    .then((res) =>
      dispatch({ type: UPLOAD_RECIPE_IMAGE_FORM, payload: res.data })
    )
    .catch((err) => dispatch({ type: GET_ERRORS, payload: err.response.data }));
};

export const getRecipe = (recipeData, history) => (dispatch) => {
  axios
    .get(`/api/recipes/${recipeData}`)
    .then((res) => dispatch({ type: GET_RECIPE_FORM, payload: res.data }))
    .catch((err) => dispatch({ type: GET_ERRORS, payload: err.response.data }));
};

export const setRecipeLoading = () => {
  return {
    type: RECIPE_FORM_LOADING,
  };
};
