import { combineReducers } from "redux";

import authReducer from "./authReducer";
import errorReducer from "./errorReducer";
import profileReducer from "./profileReducer";
import recipeReducer from "./recipeReducer";
import ingredientReducer from "./ingredientReducer";
import ingredientsFormReducer from "./ingredientsFormReducer";
import recipesReducer from "./recipesReducer";
import recipeFormReducer from "./recipeFormReducer";
import meReducer from "./meReducer";

export default combineReducers({
  me: meReducer,
  auth: authReducer,
  errors: errorReducer,
  profile: profileReducer,
  recipe: recipeReducer,
  recipeForm: recipeFormReducer,
  recipes: recipesReducer,
  ingredient: ingredientReducer,
  ingredientForm: ingredientsFormReducer,
});
