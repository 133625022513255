import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import isEmpty from "../../validation/isEmpty";
import Moment from "react-moment";

class RecipeCard extends Component {
  render() {
    const { recipe } = this.props;

    let ingredientsList = null;
    if (
      this.props.recipe.ingredients &&
      this.props.recipe.ingredients.length > 0
    ) {
      ingredientsList = this.props.recipe.ingredients.map(
        (ingredient, index) => (
          <Link
            key={ingredient._id}
            to={`/ingredient/${ingredient.ingredient.url}`}
          >
            <span className="x-small text-muted">
              {ingredient.ingredient.name}
              {index < this.props.recipe.ingredients.length - 1 ? " • " : " "}
            </span>
          </Link>
        )
      );
    } else {
      ingredientsList = "";
    }

    let imageContent = [];
    if (!isEmpty(recipe) && !isEmpty(recipe.img)) {
      recipe.img.map((image) => {
        if (imageContent.length < 1) {
          return imageContent.push(
            <div className="carousel-item active" key={image._id}>
              <img className="recipe-card-image" src={image.url} alt="Recipe" />
              <div className="position-relative">
                <div className="shape shape-bottom shape-fluid-x svg-shim text-white">
                  <svg
                    viewBox="0 0 2880 48"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M0 48h2880V0h-720C1442.5 52 720 0 720 0H0v48z"
                      fill="currentColor"
                    ></path>
                  </svg>
                </div>
              </div>
            </div>
          );
        } else {
          return imageContent.push(
            <div className="carousel-item" key={image._id}>
              <img className="recipe-card-image" src={image.url} alt="Recipe" />
              <div className="position-relative">
                <div className="shape shape-bottom shape-fluid-x svg-shim text-white">
                  <svg
                    viewBox="0 0 2880 48"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M0 48h2880V0h-720C1442.5 52 720 0 720 0H0v48z"
                      fill="currentColor"
                    ></path>
                  </svg>
                </div>
              </div>
            </div>
          );
        }
      });
    }

    return (
      <div className="card shadow-light-lg lift lift-lg">
        <div className="card-img-top">
          <Link to={`/recipe/${recipe.url}`} className="">
            <div
              id={`carousel-${recipe.url}`}
              className="carousel slide"
              data-ride="carousel"
              data-interval="2500"
            >
              <div className="carousel-inner">{imageContent}</div>
            </div>
          </Link>
        </div>

        <div className="card-body py-1">
          <div className="card-text d-flex">
            {!isEmpty(recipe.author) && !isEmpty(recipe.author.image) && (
              <div className="d-flex-inline d-sm-none mt-n4">
                <div className="avatar-xl avatar-circle img-thumbnail rounded-circle mr-3">
                  <Link to={`/profiles/${recipe.author.username}`} className="">
                    <img
                      src={recipe.author.image.url}
                      alt="..."
                      className="avatar-img rounded-circle"
                    />
                  </Link>
                </div>
              </div>
            )}

            <Link to={`/recipe/${recipe.url}`} className="card-link">
              <h5 className="recipe-title text-gray text-wrap">
                {recipe.name}
              </h5>
            </Link>
          </div>

          <div className="card-text">
            <p className="ingredients">{ingredientsList}</p>
            <Moment
              fromNow
              className="mt-n3 text-right text-muted d-block d-sm-none"
            >
              {recipe.updated}
            </Moment>
          </div>
        </div>

        {!isEmpty(recipe.author) && (
          <div className="card-meta d-none d-sm-flex">
            <hr className="card-meta-divider mb-2" />

            {!isEmpty(recipe.author.image) && (
              <div className="avatar avatar-lg mr-2">
                <Link to={`/profiles/${recipe.author.username}`} className="">
                  <img
                    src={recipe.author.image.url}
                    alt="..."
                    className="avatar-img rounded-circle"
                  />
                </Link>
              </div>
            )}

            <Link
              to={`/profiles/${recipe.author.username}`}
              className="card-footer-text text-muted"
            >
              {recipe.author.username}
            </Link>

            <small className="card-footer-text text-muted mb-0 ml-auto">
              <Moment fromNow>{recipe.updated}</Moment>
            </small>
          </div>
        )}
      </div>
    );
  }
}

RecipeCard.defaultProps = {
  showActions: true,
};

RecipeCard.propTypes = {
  recipe: PropTypes.object,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, {})(RecipeCard);
