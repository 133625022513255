import axios from "axios";

import { GET_INGREDIENTS, INGREDIENTS_LOADING, ADD_INGREDIENT, GET_ERRORS } from "./types";

export const getIngredients = (history) => (dispatch) => {
  dispatch(setIngredientLoading());
  axios
    .get(`/api/ingredients`)
    .then((res) => dispatch({ type: GET_INGREDIENTS, payload: res.data }))
    .catch((err) => dispatch({ type: GET_ERRORS, payload: err.response.data }));
};

export const addIngredient = (ingredientData, history) => (dispatch) => {
  dispatch(setIngredientLoading());
  axios
    .post(`/api/ingredients`, ingredientData)
    .then((res) => dispatch({ type: ADD_INGREDIENT, payload: res.data }))
    .catch((err) => dispatch({ type: GET_ERRORS, payload: err.response.data }));
};

export const setIngredientLoading = () => {
  return {
    type: INGREDIENTS_LOADING,
  };
};
