import {
  UPDATE_RECIPE,
  UPLOAD_RECIPE_IMAGE,
  GET_RECIPE,
  RECIPE_LOADING,
} from "../actions/types";

const initalState = {
  recipe: null,
  loading: false,
};

export default function (state = initalState, action) {
  switch (action.type) {
    case RECIPE_LOADING:
      return { ...state, loading: true };
    //todo fix
    case UPDATE_RECIPE:
      return { ...state, recipes: [action.payload, ...state.recipes] };
    case UPLOAD_RECIPE_IMAGE:
      return { ...state, image: action.payload };
    case GET_RECIPE:
      return { ...state, recipe: action.payload, loading: false };
    default:
      return state;
  }
}
