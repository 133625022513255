export const GET_ERRORS = "GET_ERRORS";
export const SET_CURRENT_USER = "SET_CURRENT_USER";

export const GET_PROFILE = "GET_PROFILE";
export const GET_PROFILE_RECIPES = "GET_PROFILE_RECIPES";
export const GET_CURRENT_PROFILE = "GET_CURRENT_PROFILE";
export const PROFILE_LOADING = "PROFILE_LOADING";
export const PROFILE_NOT_FOUND = "PROFILE_NOT_FOUND";
export const CLEAR_CURRENT_PROFILE = "CLEAR_CURRENT_PROFILE";
export const GET_PROFILES = "GET_PROFILES";
export const UPLOAD_PROFILE_IMAGE = "UPLOAD_PROFILE_IMAGE";
export const SAVE_RECIPE = "SAVE_RECIPE";
export const REMOVE_RECIPE = "REMOVE_RECIPE";
export const ME_LOADING = "ME_LOADING";

export const GET_RECIPES = "GET_RECIPES";
export const RECIPES_LOADING = "RECIPES_LOADING";

export const GET_RECIPE = "GET_RECIPE";
export const UPDATE_RECIPE = "UPDATE_RECIPE";
export const ADD_COMMENT = "ADD_COMMENT";
export const UPLOAD_RECIPE_IMAGE = "UPLOAD_RECIPE_IMAGE";
export const RECIPE_LOADING = "RECIPE_LOADING";

export const GET_RECIPE_FORM = "GET_RECIPE_FORM";
export const UPDATE_RECIPE_FORM = "UPDATE_RECIPE_FORM";
export const UPLOAD_RECIPE_IMAGE_FORM = "UPLOAD_RECIPE_IMAGE_FORM";
export const RECIPE_FORM_LOADING = "RECIPE_FORM_LOADING";

export const GET_INGREDIENT = "GET_INGREDIENT";
export const INGREDIENT_LOADING = "INGREDIENT_LOADING";
export const GET_INGREDIENT_RECIPES = "GET_INGREDIENT_RECIPES";
export const GET_INGREDIENT_META = "GET_INGREDIENT_META";

export const GET_INGREDIENTS = "GET_INGREDIENTS";
export const INGREDIENTS_LOADING = "INGREDIENTS_LOADING";
export const ADD_INGREDIENT = "ADD_INGREDIENT";
