import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  getCurrentProfile,
  saveRecipe,
  removeRecipe,
} from "../../actions/meActions";
import Spinner from "../common/Spinner";
import { Link } from "react-router-dom";
import RecipeListing from "./RecipeListing";
import isEmpty from "../../validation/isEmpty";

class Dashboard extends Component {
  constructor(props) {
    super(props);

    this.onAddMealPrep = this.onAddMealPrep.bind(this);
    this.onRemoveMealPrep = this.onRemoveMealPrep.bind(this);
  }

  componentDidMount() {
    this.props.getCurrentProfile();
    //this.props.getProfileRecipes("rick");

    window.scrollTo(0, 0);
  }

  onAddMealPrep(index) {
    if (isEmpty(this.props.me.me.bookmarks)) {
      return;
    }
    this.props.addBookmarkList(index);
  }

  onRemoveMealPrep(index) {
    if (isEmpty(this.props.me.me.bookmarks)) {
      return;
    }
    this.props.removeRecipe(index);
  }

  render() {
    const { me, loading } = this.props.me;

    let sidebarContent;
    let mainContent;

    if (isEmpty(me) && loading) {
      return (
        <div className="dashboard">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <Spinner />
              </div>
            </div>
          </div>
        </div>
      );
    }

    // Check to see if the user has setup a profile.
    if (!isEmpty(me)) {
      this.props.history.push(`/profiles/${me.username}`);

      sidebarContent = (
        <>
          <Link to={`/profiles/${me.username}`}>
            {!isEmpty(me.image) && (
              <img
                src={me.image.url}
                className="img-fluid img-thumbnail"
                alt="..."
              />
            )}
          </Link>
          <Link to="/update-profile" className="card my-1 lift">
            <div className="card-body">
              <h5 className="card-title">
                {" "}
                <i className="fas fa-user-circle text-primary mr-2" />
                Edit Profile
              </h5>

              <p className="card-text">
                Preferences and settings for your Social Spoon account
              </p>
            </div>
          </Link>
          <Link to="/update-recipe" className="card my-1 lift">
            <div className="card-body">
              <h5 className="card-title">
                {" "}
                <i className="fas fa-plus-circle text-primary mr-2" />
                New Recipe
              </h5>
              <p className="card-text">
                Share your recipes with the world, or keep your recipes a secret
              </p>
            </div>
          </Link>
          <Link to="/shopping-list" className="card my-1 lift">
            <div className="card-body">
              <h5 className="card-title">
                {" "}
                <i className="fas fa-shopping-basket text-primary mr-2" />
                Shopping List
              </h5>
              <p className="card-text">
                Generate a list of ingredients you'll need to make your recipes
              </p>
            </div>
          </Link>
        </>
      );

      mainContent = (
        <div className="row">
          {/* <div className="col-12 col-md-6">
            <RecipeListing
              title="My Recipes"
              recipes={me.myrecipes}
              onAddMealPrep={this.onAddMealPrep}
            />
          </div> */}
          <div className="col-12 col-md-6">
            <RecipeListing
              title="Meal Prep"
              recipes={me.bookmarks}
              onRemoveMealPrep={this.onRemoveMealPrep}
            />
          </div>
        </div>
      );
    } else {
      // User is logged in but has not created a profile
      sidebarContent = (
        <div>
          <div className="row">
            <div className="m-4">
              <div className="display-4 text-center site-title">Hello!</div>

              <p className="lead text-center mb-4">
                Create a profile to use these features of the site:
              </p>
              <p>
                <ul>
                  <li>recipe creation</li>
                  <li>recipe sharing</li>
                  <li>commenting</li>
                  <li>meal prep tools</li>
                </ul>
              </p>

              <Link
                to="/update-profile"
                className="btn btn-block btn-primary mt-4"
              >
                Create Profile
              </Link>

              <Link className="btn btn-outline-primary btn-block mt-4" to="/">
                I'll do this later
              </Link>
            </div>
          </div>
        </div>
      );

      mainContent = <p>Todo: Render recipes here.</p>;
    }

    return (
      <div className="dashboard">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-3 sidebar">
              <div className="sidebar-sticky">{sidebarContent}</div>
            </div>
            <div className="col-md-9">{mainContent}</div>
          </div>
        </div>
      </div>
    );
  }
}

Dashboard.propTypes = {
  getCurrentProfile: PropTypes.func.isRequired,
  saveRecipe: PropTypes.func.isRequired,
  removeRecip: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  me: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  me: state.me,
  auth: state.auth,
});

export default connect(mapStateToProps, {
  getCurrentProfile,
  saveRecipe,
  removeRecipe,
})(Dashboard);
