import React, { Component } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./store";
import PrivateRoute from "./components/common/PrivateRoute";
import Navbar from "./components/layout/Navbar";
import Footer from "./components/layout/Footer";
import Landing from "./components/layout/Landing";
import Legal from "./components/layout/Legal";
import Register from "./components/auth/Register";
import Login from "./components/auth/Login";
import Dashboard from "./components/dashboard/Dashboard";
import UpdateProfileForm from "./components/profile/UpdateProfileForm";
import UpdateRecipeForm from "./components/recipe/UpdateRecipeForm";
import ViewProfile from "./components/profile/ViewProfile.js";
import ShoppingList from "./components/dashboard/ShoppingList";
import Recipes from "./components/recipe/Recipes";
import ViewRecipe from "./components/recipe/ViewRecipe";
import Ingredient from "./components/ingredient/ViewIngredient";
import NotFound from "./components/not-found/NotFound";
import jwt_decode from "jwt-decode";
import setAuthToken from "./utils/setAuthToken";
import { setCurrentUser, logoutUser } from "./actions/authActions";
import { clearCurrentProfile } from "./actions/meActions";
import { Helmet } from "react-helmet";
import "./App.scss";

// Check for token
if (localStorage.jwtToken) {
  // Set auth Token header auth
  setAuthToken(localStorage.jwtToken);
  //decode token and get user info and expiration
  const decoded = jwt_decode(localStorage.jwtToken);
  // Set user and isAuthenticated
  store.dispatch(setCurrentUser(decoded));

  //console.log("CHECKING");
  // Check for exipired token
  const currentTime = Date.now() / 1000;
  if (decoded.exp < currentTime) {
    store.dispatch(logoutUser());
    store.dispatch(clearCurrentProfile());
    window.location.href = "/login";
  }
}

class App extends Component {
  render() {
    return (
      <div>
        <Helmet>
          <title>The Social Spoon</title>
          <meta
            name="description"
            content="We are a social media platform dedicated to sharing recipes."
          ></meta>
        </Helmet>
        <Provider store={store}>
          <Router>
            <div className="App">
              <Navbar />
              <Route exact path="/" component={Recipes} />
              <div className="main">
                <Route exact path="/register" component={Register} />
                <Route exact path="/login" component={Login} />
                <Route exact path="/landing" component={Landing} />
                <Route exact path="/legal" component={Legal} />
                <Route exact path="/recipe/:name" component={ViewRecipe} />
                {/* <Route exact path="/recipes" component={Recipes} /> */}
                <Route
                  exact
                  path="/profiles/:username"
                  component={ViewProfile}
                />
                <Route exact path="/ingredient/:name" component={Ingredient} />
                <Switch>
                  <PrivateRoute
                    exact
                    path="/update-profile"
                    component={UpdateProfileForm}
                  />
                </Switch>
                <Switch>
                  <PrivateRoute exact path="/dashboard" component={Dashboard} />
                </Switch>

                <Switch>
                  <PrivateRoute
                    exact
                    path="/shopping-list"
                    component={ShoppingList}
                  />
                </Switch>
                <Switch>
                  <PrivateRoute
                    exact
                    path="/update-recipe/:name"
                    component={UpdateRecipeForm}
                  />
                </Switch>
                <Switch>
                  <PrivateRoute
                    exact
                    path="/update-recipe/"
                    component={UpdateRecipeForm}
                  />
                </Switch>
                <Route exact path="/not-found" component={NotFound} />
              </div>
              <Footer />
            </div>
          </Router>
        </Provider>
      </div>
    );
  }
}

export default App;
