import React, { Component } from "react";
import PropTypes from "prop-types";
import RecipeCard from "./RecipeCard";
import MiniRecipeCard from "./MiniRecipeCard";
import isEmpty from "../../validation/isEmpty";

class RecipeFeed extends Component {
  render() {
    const { recipes } = this.props;
    const { products } = this.props;

    var recipeContent = [];

    if (isEmpty(recipes)) {
      return "Nothing is here, yet!";
    } else {
      recipes
        .filter((recipe) => {
          if (recipe.deleted) return false;
          else if (recipe.private === true) return false;
          else return true;
        })
        .map((recipe) => {
          let recipeCard;

          if (!isEmpty(this.props.mini) && this.props.mini === true) {
            recipeCard = <MiniRecipeCard key={recipe._id} recipe={recipe} />;
          } else {
            recipeCard = <RecipeCard key={recipe._id} recipe={recipe} />;
          }

          let randomNum = Math.random();
          if (!isEmpty(products) && randomNum >= 0 && randomNum <= 0.03) {
            // get random product from array
            var productIndex = Math.floor(Math.random() * products.length);
            var product = products[productIndex];

            products.splice(productIndex, 1);

            let productCard = (
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={product.referralUrl}
              >
                <div className="card text-dark">
                  <img
                    src="https://res.cloudinary.com/htypemczi/image/upload/v1613710943/CITYPNG.COM_Buy_Now_At_Amazon_com_-_638x204.png"
                    className="product-card-ad p-2 mx-auto"
                    alt=""
                  ></img>

                  <img
                    className="img-fluid card-img product-card"
                    src={product.imgUrl}
                    alt=""
                  />

                  <div className="card-body py-1">
                    <blockquote className="blockquote mb-0 card-text text-center">
                      {product.name}
                    </blockquote>
                  </div>
                </div>
              </a>
            );

            recipeContent.push(productCard);
          }

          recipeContent.push(recipeCard);
        });
      //
      return recipeContent;
    }
  }
}

RecipeFeed.propTypes = {
  recipes: PropTypes.array.isRequired,
};

export default RecipeFeed;
