import axios from "axios";

import {
  GET_PROFILE,
  GET_PROFILE_RECIPES,
  PROFILE_LOADING,
  GET_ERRORS,
} from "./types";

export const getProfileByUsername = (profileData, history) => (dispatch) => {
  dispatch(setProfileLoading());

  axios
    .get(`/api/profiles/username/${profileData}`)
    .then((res) => dispatch({ type: GET_PROFILE, payload: res.data }))
    .catch((err) => dispatch({ type: GET_ERRORS, payload: err.response.data }));
};

export const getProfileRecipes = (profileData, history) => (dispatch) => {
  dispatch(setProfileLoading());
  axios
    .get(`/api/recipes/profile/${profileData}`)
    .then((res) => dispatch({ type: GET_PROFILE_RECIPES, payload: res.data }))
    .catch((err) => dispatch({ type: GET_ERRORS, payload: err.response.data }));
};

export const setProfileLoading = () => {
  return {
    type: PROFILE_LOADING,
  };
};
