import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import {
  getIngredientByID,
  getRecipesByIngredient,
  getIngredientMeta,
} from "../../actions/ingredientActions";
import RecipeFeed from "../recipe/RecipeFeed";
import isEmpty from "../../validation/isEmpty";

class Ingredient extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ingredient: {},
      recipes: {},
    };
  }

  componentDidMount() {
    if (this.props.match.params.name) {
      this.props.getIngredientMeta(this.props.match.params.name);
      this.props.getIngredientByID(this.props.match.params.name);
      this.props.getRecipesByIngredient(this.props.match.params.name);

      window.scrollTo(0, 0);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.match.params.name !== nextProps.match.params.name) {
      if (this.props.match.params.name) {
        this.props.getIngredientMeta(nextProps.match.params.name);
        this.props.getIngredientByID(nextProps.match.params.name);
        this.props.getRecipesByIngredient(this.props.match.params.name);
      }
    }
  }

  render() {
    const { ingredient, recipes, meta } = this.props.ingredient;
    let ingredientContent;
    let recipeContent;

    var imgStyle = {
      "max-height": "30rem",
      "object-fit": "cover",
    };

    if (!isEmpty(ingredient) && !isEmpty(recipes)) {
      recipeContent = (
        <div className="card-columns card-columns-large">
          <RecipeFeed recipes={recipes} />
        </div>
      );

      ingredientContent = (
        <div>
          <div className="row">
            <div className="m-4">
              <div className="display-4 text-center site-title">
                {ingredient.name}
              </div>
              <p className="lead text-center mb-4">
                {recipes.length}{" "}
                {recipes.length > 1 ? "recipes use" : "recipe uses"}{" "}
                {ingredient.name}
              </p>
              {meta && (
                <img
                  src={meta.thumbnail.source}
                  className="img-thumbnail mh-50 mb-4"
                  style={imgStyle}
                  alt="metadata"
                />
              )}
              {meta && <p className="lead">{meta.extract}</p>}
            </div>
          </div>
        </div>
      );
    }
    return (
      <div className="profile">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-3 sidebar">
              <div className="sidebar-sticky">{ingredientContent}</div>
            </div>

            <div className="col-md-9">{recipeContent}</div>
          </div>
        </div>
      </div>
    );
  }
}

Ingredient.propTypes = {
  getIngredientByID: PropTypes.func.isRequired,
  getRecipesByIngredient: PropTypes.func.isRequired,
  getIngredientMeta: PropTypes.func.isRequired,
  ingredient: PropTypes.object.isRequired,
  recipes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  ingredient: state.ingredient,
  recipes: state.recipes,
});

export default connect(mapStateToProps, {
  getIngredientByID,
  getRecipesByIngredient,
  getIngredientMeta,
})(withRouter(Ingredient));
