import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";

const CheckBox = ({
  name,
  defaultChecked,
  label,
  error,
  info,
  onChange,
  disabled,
}) => {
  return (
    <div className="custom-control custom-checkbox">
      <input
        type="checkbox"
        className={classnames("custom-control-input", {
          "is-invalid": error,
        })}
        id={name}
        defaultChecked={defaultChecked}
        disabled={disabled}
        onChange={onChange}
        name={name}
      ></input>
      <label className="custom-control-label" htmlFor={name}>
        {label}
      </label>
      {info && <small className="form-text text-muted">{info}</small>}
      {error && <div className="invalid-feedback">{error}</div>}
    </div>
  );
};

CheckBox.propTypes = {
  name: PropTypes.string.isRequired,
  defaultChecked: PropTypes.bool.isRequired,
  info: PropTypes.string,
  error: PropTypes.string,
  onColor: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.string,
};

CheckBox.defaultProps = {};

export default CheckBox;
