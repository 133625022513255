import React, { Component } from "react";
import PropTypes from "prop-types";
import TextAreaFieldGroup from "../common/TextAreaFieldGroup";
import { connect } from "react-redux";
import { getRecipe, addComment } from "../../actions/recipeActions";
import {
  getCurrentProfile,
  saveRecipe,
  removeRecipe,
} from "../../actions/meActions";
import { Link } from "react-router-dom";
import Moment from "react-moment";
import isEmpty from "../../validation/isEmpty";
import isRecipeSavedInMe from "../../utils/isRecipeSavedInMe";
import Spinner from "../common/Spinner";
import { Helmet } from "react-helmet";
import CountUp from "react-countup";
import Flickity from "react-flickity-component";
import {
  EmailShareButton,
  FacebookShareButton,
  PinterestShareButton,
  RedditShareButton,
  TwitterShareButton,
} from "react-share";

class ViewRecipe extends Component {
  constructor(props) {
    super(props);

    this.state = {
      comment: "",
      recipe: {},
      me: {},
      errors: {},
    };

    //this.addPrep = this.addPrep.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onSaveRecipe = this.onSaveRecipe.bind(this);
    this.onRemoveRecipe = this.onRemoveRecipe.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    // if (nextProps.errors) {
    //   this.setState({ errors: nextProps.errors });
    // }
  }

  // UNSAFE_componentWillReceiveProps(nextProps) {
  //   if (nextProps.auth.isAuthenticated) {
  //     this.props.history.push("/");
  //   }

  //   if (nextProps.errors) {
  //     this.setState({ errors: nextProps.errors });
  //   }
  // }

  onSaveRecipe(e) {
    e.preventDefault();

    var saveData = {
      collection_name: "Saved",
      recipe_id: this.props.recipe.recipe._id,
    };

    this.props.saveRecipe(saveData);
  }

  onRemoveRecipe(e) {
    e.preventDefault();

    var removeData = {
      collection_name: "Saved",
      recipe_id: this.props.recipe.recipe._id,
    };

    console.log("removeData", removeData);

    this.props.removeRecipe(removeData);
  }

  componentDidMount() {
    if (this.props.match.params.name) {
      this.props.getCurrentProfile();

      this.props.getRecipe(this.props.match.params.name);
      window.scrollTo(0, 0);
    }
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  onSubmit(e) {
    e.preventDefault();
    if (isEmpty(this.state.comment)) return;

    const newComment = {
      text: this.state.comment,
      user: this.props.me.me,
      created: new Date(),
    };

    //TODO: Immediately updates the view, need to wait for post
    this.props.recipe.recipe.comments.unshift(newComment);

    this.props.addComment(
      this.props.recipe.recipe._id,
      newComment,
      this.props.history
    );

    // Clears the input from the textbox
    this.setState({ comment: "" });
  }

  render() {
    let { recipe, loading } = this.props.recipe;
    let { me } = this.props.me;
    const { errors } = this.state;

    var ingredientMetaList = [];

    if (isEmpty(recipe) || loading) {
      return <Spinner />;
    }

    let shareURL = "https://socialspoon.org/recipe/" + recipe.url;

    let productsContent = [];
    var flickOptions = {
      wrapAround: true,
      draggable: true,
      freeScroll: true,
      autoPlay: 2000,
    };
    var productStyle = {
      width: "15rem",
      height: "300px",
    };
    if (!isEmpty(recipe) && !isEmpty(recipe.products)) {
      //flickity
      recipe.products.map((product) => {
        return productsContent.push(
          <div>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={product.product.referralUrl}
            >
              <div className="card text-dark" style={productStyle}>
                <img
                  className="card-img product-card"
                  src={product.product.imgUrl}
                  alt=""
                />

                <div className="card-body py-1">
                  <blockquote className="blockquote mb-0 card-text text-center">
                    {product.product.name}
                  </blockquote>
                </div>
              </div>
            </a>
          </div>
        ); //push
      }); // map
    } //if

    let imageContent = [];
    if (!isEmpty(recipe) && !isEmpty(recipe.img)) {
      recipe.img.map((image) => {
        if (imageContent.length < 1) {
          return imageContent.push(
            <div className="carousel-item active" key={image._id}>
              <img
                className="d-block w-100 img-fluid recipe-feature-image"
                src={image.url}
                alt="Recipe"
              />
              <div className="position-relative">
                <div className="shape shape-bottom shape-fluid-x svg-shim text-white">
                  <svg
                    viewBox="0 0 2880 48"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M0 48h2880V0h-720C1442.5 52 720 0 720 0H0v48z"
                      fill="currentColor"
                    ></path>
                  </svg>
                </div>
              </div>
            </div>
          );
        } else {
          return imageContent.push(
            <div className="carousel-item" key={image._id}>
              <img
                className="d-block w-100 img-fluid recipe-feature-image"
                src={image.url}
                alt="Recipe"
              />

              <div className="position-relative">
                <div className="shape shape-bottom shape-fluid-x svg-shim text-white">
                  <svg
                    viewBox="0 0 2880 48"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M0 48h2880V0h-720C1442.5 52 720 0 720 0H0v48z"
                      fill="currentColor"
                    ></path>
                  </svg>
                </div>
              </div>
            </div>
          );
        }
      });
    }

    let comments = [];
    if (!isEmpty(recipe) && !isEmpty(recipe.comments)) {
      recipe.comments.map((comment) => {
        if (!isEmpty(comment.user)) {
          return comments.push(
            <li className="mb-5" key={comment.user.username + comment.created}>
              <div className="media align-items-center mb-2">
                <Link to={`/profiles/${comment.user.username}`}>
                  <div className="avatar avatar-xl avatar-circle mr-3">
                    <img
                      className="avatar-img"
                      src={comment.user.image.url}
                      alt="..."
                    />
                  </div>
                </Link>

                <div className="media-body">
                  <div className="d-flex justify-content-between align-items-center">
                    <Link to={`/profiles/${comment.user.username}`}>
                      <span className="h5 mb-0">{comment.user.username}</span>
                    </Link>
                    <Moment fromNow className="mx-2 text-muted">
                      {comment.created}
                    </Moment>
                  </div>
                </div>
              </div>

              <p>{comment.text}</p>
            </li>
          );
        } else {
          return "";
        }
      });
    }

    let ingredientContent = [];
    if (!isEmpty(recipe) && !isEmpty(recipe.ingredients)) {
      recipe.ingredients.map((ingredient) => {
        ingredientMetaList.push(ingredient.ingredient.name + " ");

        return ingredientContent.push(
          <li
            className="list-group-item d-flex justify-content-between align-items-center"
            key={ingredient._id}
          >
            <span className="badge badge-primary badge-pill">
              {ingredient.amount} {ingredient.unit && ingredient.unit}
            </span>

            <Link to={`/ingredient/${ingredient.ingredient.url}`}>
              <span>{ingredient.ingredient.name}</span>
            </Link>
          </li>
        );
      });
    }

    let stepsContent = [];
    if (!isEmpty(recipe) && !isEmpty(recipe.steps)) {
      recipe.steps.map((step, index) => {
        return stepsContent.push(
          // <p key={step._id} className="method-paragraph">
          //   <strong>Step {index + 1}:</strong> {step.step}
          // </p>

          <div className="d-flex">
            <span className="badge badge-lg badge-rounded-circle badge-primary">
              <span>{index + 1}</span>
            </span>
            <div className="ml-3 mb-2">
              <p className="">{step.step}</p>
            </div>
          </div>
        );
      });
    }

    return (
      <div>
        <Helmet>
          <title>The Social Spoon: {recipe.name}</title>
          <meta name="description"></meta>
        </Helmet>
        <div
          id="carouselExampleControls"
          className="carousel slide"
          data-ride="carousel"
        >
          <div className="carousel-inner">{imageContent}</div>
          {!isEmpty(recipe) && !isEmpty(recipe.img) && recipe.img.length > 1 && (
            <>
              <a
                className="carousel-control-prev"
                href="#carouselExampleControls"
                role="button"
                data-slide="prev"
              >
                <span
                  className="carousel-control-prev-icon"
                  aria-hidden="true"
                ></span>
                <span className="sr-only">Previous</span>
              </a>
              <a
                className="carousel-control-next"
                href="#carouselExampleControls"
                role="button"
                data-slide="next"
              >
                <span
                  className="carousel-control-next-icon"
                  aria-hidden="true"
                ></span>
                <span className="sr-only">Next</span>
              </a>
            </>
          )}
        </div>

        <div className="container">
          {(!isEmpty(recipe.author) &&
            !isEmpty(recipe.author.image) &&
            isEmpty(recipe) &&
            isEmpty(recipe.img)) ||
            (recipe.img.length === 0 && (
              <div className="row">
                <div className="col-12">
                  <div className="d-block avatar-xxl avatar-circle img-thumbnail rounded-circle m-1">
                    <Link
                      to={`/profiles/${recipe.author.username}`}
                      className=""
                    >
                      <img
                        className="avatar-img rounded-circle"
                        src={recipe.author.image.url}
                        alt="..."
                      />
                    </Link>
                  </div>
                </div>
              </div>
            ))}
          {!isEmpty(recipe.author) &&
            !isEmpty(recipe.author.image) &&
            !isEmpty(recipe) &&
            !isEmpty(recipe.img) && (
              <div className="row">
                <div className="col-12">
                  <div className="d-block avatar-xxl avatar-circle img-thumbnail rounded-circle mr-1 mt-n5">
                    <Link
                      to={`/profiles/${recipe.author.username}`}
                      className=""
                    >
                      <img
                        className="avatar-img rounded-circle"
                        src={recipe.author.image.url}
                        alt="..."
                      />
                    </Link>
                  </div>
                </div>
              </div>
            )}
          <div className="row">
            <div className="col-3"></div>

            <div className="col-9">
              <h1 className="recipe-page-title text-dark text-right text-wrap">
                {recipe.name}
              </h1>
            </div>
          </div>
          <div className="row py-0">
            <div className="col-5 col-md-5 px-1">
              <div className="media align-middle">
                <div className="media-body font-size-1 ml-3">
                  <span className="h6">
                    <Link
                      to={`/profiles/${recipe.author.username}`}
                      className=""
                    >
                      {recipe.author.username}
                    </Link>
                  </span>

                  {isEmpty(me) && (
                    <Moment className="d-block text-muted recipe-text" fromNow>
                      {recipe.updated}
                    </Moment>
                  )}

                  {!isEmpty(me) &&
                    !isEmpty(recipe) &&
                    me.user._id !== recipe.author.user && (
                      <Moment
                        className="d-block text-muted recipe-text"
                        fromNow
                      >
                        {recipe.updated}
                      </Moment>
                    )}

                  {!isEmpty(me) &&
                    !isEmpty(recipe) &&
                    me.user._id === recipe.author.user && (
                      <div className="d-block">
                        <Moment className="text-muted text-small" fromNow>
                          {recipe.updated}
                        </Moment>
                        <Link to={`/update-recipe/${recipe.url}`}>
                          <span className="text-muted text-small">
                            {" "}
                            • edit recipe
                          </span>
                        </Link>
                      </div>
                    )}

                  <div className="d-flex flex-wrap">
                    <FacebookShareButton
                      url={shareURL}
                      title={recipe.name}
                      quote={recipe.name}
                      hashtag="socialspoon"
                    >
                      <div className="badge badge-lg badge-rounded-circle badge-primary">
                        <i className="fab fa-facebook-f"></i>
                      </div>
                    </FacebookShareButton>
                    <PinterestShareButton
                      url={shareURL}
                      media={shareURL}
                      title={recipe.name}
                    >
                      <div className="badge badge-lg badge-rounded-circle badge-primary ml-1">
                        <i className="fab fa-pinterest-p"></i>
                      </div>
                    </PinterestShareButton>
                    <TwitterShareButton url={shareURL} title={recipe.name}>
                      <div className="badge badge-lg badge-rounded-circle badge-primary ml-1">
                        <i className="fab fa-twitter"></i>
                      </div>
                    </TwitterShareButton>

                    <RedditShareButton url={shareURL} title={recipe.name}>
                      <div className="badge badge-lg badge-rounded-circle badge-primary ml-1">
                        <i className="fab fa-reddit-alien"></i>
                      </div>
                    </RedditShareButton>

                    <EmailShareButton url={shareURL} title={recipe.name}>
                      <div className="badge badge-lg badge-rounded-circle badge-primary ml-1">
                        <i className="far fa-envelope"></i>
                      </div>
                    </EmailShareButton>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-7 col-md-7 d-flex flex-row-reverse recipe-text p-0">
              <div className="card p-0 border-0 mx-1">
                <span className="display-4 text-primary d-block">
                  <CountUp
                    start={0}
                    end={parseInt(recipe.preptime)}
                    duration={3}
                  ></CountUp>
                </span>
                <span className="text-muted text-small text-center mb-0 d-block">
                  minutes
                </span>
              </div>
              <div className="card p-0 border-0 mx-1">
                <span className="display-4 text-primary d-block">
                  <CountUp
                    start={0}
                    end={parseInt(recipe.ingredients.length)}
                    duration={3}
                  ></CountUp>
                </span>
                <span className="text-muted text-small text-center mb-0 d-block">
                  ingredients
                </span>
              </div>
              <div className="card border-0 mx-1">
                <span className="display-4 text-primary d-block">
                  <CountUp
                    start={0}
                    end={parseInt(recipe.serves)}
                    duration={3}
                  ></CountUp>
                </span>
                <span className="text-muted text-small text-center mb-0 d-block">
                  servings
                </span>
              </div>
            </div>
          </div>
          <hr className="line-style" />
          <div className="row">
            <div className="col-sm-12 col-md-5 col-lg-4 mt-3 mt-sm-2">
              <h3 className="mb-3">Ingredients:</h3>
              <ul className="list-group list-group-flush">
                {ingredientContent}
              </ul>
            </div>

            <div className="col-sm-12 col-md-7 col-lg-8 mt-3 mt-sm-2">
              <h3 className="mb-3">Method:</h3>
              {stepsContent}
            </div>
          </div>
          {!isEmpty(recipe) && !isEmpty(recipe.products) && (
            <>
              <hr className="line-style" />
              <div className="col-sm-12 col-md-7 col-lg-8 mt-3 mt-sm-2">
                <h3 className="mb-3">Tools:</h3>
              </div>
              <div className="col-12">
                <Flickity elementType={"div"} options={flickOptions}>
                  {productsContent}
                </Flickity>
              </div>
            </>
          )}
          {isEmpty(me) && (
            <div className="row mt-4 mb-4">
              <div className="col-3"></div>
              <div className="col-6">
                <Link
                  className="btn btn-outline-secondary btn-block mt-4 lift"
                  to="/login"
                >
                  Sign in to comment and save this recipe.{" "}
                  <i className="fa fa-carrot text-warning"></i>
                </Link>
              </div>
              <div className="col-3"></div>
            </div>
          )}

          {!isEmpty(me) && !isRecipeSavedInMe(me, recipe._id) && (
            <div className="row mt-4 mb-4">
              <div className="col-12">
                <button
                  type="button"
                  onClick={this.onSaveRecipe}
                  className="btn btn-outline-secondary btn-wide lift"
                >
                  Save Recipe
                </button>
              </div>
            </div>
          )}
          {!isEmpty(me) && isRecipeSavedInMe(me, recipe._id) && (
            <div className="row mt-4 mb-4">
              <div className="col-12">
                <button
                  type="button"
                  onClick={this.onRemoveRecipe}
                  className="btn btn-secondary btn-wide lift"
                >
                  Recipe Saved!
                </button>
              </div>
            </div>
          )}

          {!isEmpty(me) && (
            <>
              <div className="row mt-4 pt-4">
                <div className="col-2"></div>
                <div className="col-8">
                  <h3 className="mb-3">
                    <span className="badge badge-primary badge-pill mr-2">
                      {recipe.comments.length}
                    </span>
                    Comments:
                  </h3>
                </div>
                <div className="col-2"></div>
              </div>
              <div className="row">
                <div className="col-2"></div>
                <div className="col-8">
                  <form
                    id="addComment"
                    noValidate
                    className="form-group"
                    onSubmit={this.onSubmit}
                  >
                    <TextAreaFieldGroup
                      placeholder={"What do you think of this recipe?"}
                      name="comment"
                      value={this.state.comment}
                      error={errors.comment}
                      onChange={this.onChange}
                    />
                  </form>
                </div>
                <div className="col-2"></div>
              </div>
              <div className="row mb-2">
                <div className="col-2"></div>
                <div className="col-8">
                  <button
                    form="addComment"
                    type="submit"
                    className="btn btn-primary btn-wide float-right lift"
                  >
                    Post
                  </button>
                </div>
                <div className="col-2"></div>
              </div>
              <div className="row">
                <div className="col-2"></div>
                <div className="col-8">
                  <ul className="list-unstyled">{comments}</ul>
                </div>
                <div className="col-2"></div>
              </div>
            </>
          )}
        </div>
      </div>
    );
  }
}

ViewRecipe.propTypes = {
  getCurrentProfile: PropTypes.func.isRequired,
  addComment: PropTypes.func.isRequired,
  getRecipe: PropTypes.func.isRequired,
  saveRecipe: PropTypes.func,
  removeRecipe: PropTypes.func,
  recipe: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  recipe: state.recipe,
  me: state.me,
  errors: state.errors,
});

export default connect(mapStateToProps, {
  getRecipe,
  addComment,
  saveRecipe,
  removeRecipe,
  getCurrentProfile,
})(ViewRecipe);
