import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import TextFieldGroup from "../common/TextFieldGroup";
import {
  updateProfile,
  getCurrentProfile,
  uploadProfileImage,
} from "../../actions/meActions";
import isEmpty from "../../validation/isEmpty";
import Uploader from "../common/Uploader";

class UpdateProfileForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: "",
      username: "",
      bio: "",
      image: {},
      errors: {},
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onUploadImage = this.onUploadImage.bind(this);
  }

  componentDidMount() {
    this.props.getCurrentProfile();
    window.scrollTo(0, 0);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!isEmpty(nextProps.errors)) {
      this.setState({ errors: nextProps.errors });
    }

    if (!isEmpty(nextProps.me.me)) {
      const me = nextProps.me.me;

      me.username = !isEmpty(me.username) ? me.username : this.state.username;

      me.name = !isEmpty(me.name) ? me.name : this.state.name;
      me.bio = !isEmpty(me.bio) ? me.bio : this.state.bio;
      me.image = !isEmpty(me.image) ? me.image : this.state.image;

      this.setState({
        username: me.username,
        name: me.name,
        image: me.image,
        bio: me.bio,
      });
    }
  }

  onUploadImage = (e) => {
    e.preventDefault();

    const imgData = new FormData();
    imgData.append("file", e.target.files[0]);
    imgData.append("path", "profiles/");

    this.props.uploadProfileImage(imgData);
  };

  onSubmit(e) {
    e.preventDefault();

    const profileData = {
      name: this.state.name,
      username: this.state.username,
      image: this.state.image,
      bio: this.state.bio,
    };

    this.props.updateProfile(profileData, this.props.history);
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  render() {
    const { errors } = this.state;

    return (
      <div className="create-profile">
        <div className="container">
          <div className="row">
            <div className="col-md-8 m-auto">
              <h1 className="display-4 text-center site-title">
                Tell us a little about yourself.
              </h1>
              <p className="lead text-center">Edit your profile.</p>
              <form noValidate onSubmit={this.onSubmit}>
                <TextFieldGroup
                  placeholder="Username"
                  name="username"
                  value={this.state.username}
                  onChange={this.onChange}
                  error={errors.username}
                  label="Username"
                />
                <TextFieldGroup
                  placeholder="Full Name"
                  name="name"
                  value={this.state.name}
                  onChange={this.onChange}
                  error={errors.name}
                  label="Full Name"
                />
                <div className="form-row">
                  <div className="form-group col-md-6">
                    <Uploader
                      name="img"
                      label="Profile Picture"
                      onChange={this.onUploadImage}
                    />
                    {!isEmpty(this.state.image) && (
                      <img
                        className="img-fluid recipe-form-image"
                        src={this.state.image.url}
                        onChange={this.onChange}
                        alt="Profile"
                      />
                    )}
                  </div>
                </div>
                <TextFieldGroup
                  placeholder="Bio limited to 50 characters"
                  name="bio"
                  value={this.state.bio}
                  onChange={this.onChange}
                  error={errors.bio}
                  label="Bio"
                />
                <input
                  type="submit"
                  value="Update profile"
                  className="btn btn-primary btn-block mt-4"
                />
                {this.state.errors.general && (
                  <div className="invalid-feedback">
                    {this.state.errors.general}
                  </div>
                )}
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

UpdateProfileForm.propTypes = {
  me: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  updateProfile: PropTypes.func.isRequired,
  getCurrentProfile: PropTypes.func.isRequired,
  uploadProfileImage: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  me: state.me,
  errors: state.errors,
});

export default connect(mapStateToProps, {
  updateProfile,
  getCurrentProfile,
  uploadProfileImage,
})(withRouter(UpdateProfileForm));
