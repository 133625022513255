import axios from "axios";

import { GET_RECIPES, RECIPES_LOADING, GET_ERRORS } from "./types";

export const getRecipes = (recipeData, history) => (dispatch) => {
  dispatch(setRecipesLoading());
  axios
    .get(`/api/recipes/all/paginated/${recipeData}`)
    .then((res) => {
      dispatch({ type: GET_RECIPES, payload: res.data });
    })
    .catch((err) => dispatch({ type: GET_ERRORS, payload: err.response.data }));
};

export const setRecipesLoading = () => {
  return {
    type: RECIPES_LOADING,
  };
};
