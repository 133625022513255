import { GET_INGREDIENTS, INGREDIENTS_LOADING, ADD_INGREDIENT } from "../actions/types";

const initialState = {
  ingredients: null,
  ingredient: {},
  loading: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case INGREDIENTS_LOADING:
      return { ...state, loading: true };
    case GET_INGREDIENTS:
      return { ...state, ingredients: action.payload, loading: false };
    case ADD_INGREDIENT:
        return { ...state, ingredient: action.payload, loading: false };
  
    default:
      return state;
  }
}
