/* eslint-disable array-callback-return */
import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getCurrentProfile } from "../../actions/meActions";
import Spinner from "../common/Spinner";
import isEmpty from "../../validation/isEmpty";

class ShoppingList extends Component {
  componentDidMount() {
    this.props.getCurrentProfile();
    window.scrollTo(0, 0);
  }

  render() {
    let { me, loading } = this.props.me;

    let profileContent;

    if (isEmpty(me) || isEmpty(me.bookmarks) || loading) {
      profileContent = <Spinner />;
    } else {
      // Build all ingredients
      var ingredients = [];
      //todo: change from map to foreach, because we're expected to return a value for map
      me.bookmarks.map((recipe) => {
        recipe.ingredients.map((ing) => {
          ingredients.push(ing);
        });
      });

      // Group by aisle
      var groups = ingredients.reduce(function (r, a) {
        r[a.ingredient.location] = r[a.ingredient.location] || [];
        r[a.ingredient.location].push(a);
        return r;
      }, Object.create(null));

      var groupContent = [];
      var groupLabels = Object.getOwnPropertyNames(groups);

      for (var i = 0; i < groupLabels.length; i++) {
        groupContent.push(<div className="h6">{groupLabels[i]}</div>);

        for (var j = 0; j < groups[groupLabels[i]].length; j++) {
          groupContent.push(
            <li>{groups[groupLabels[i]][j].ingredient.name}</li>
          );
        }
      }

      profileContent = (
        <div>
          <div className="row">
            <div className="col-sm-3"></div>
            <div className="col-sm-6">
              <h1 className="display-4 text-center site-title">
                Shopping List
              </h1>

              <div className="text-center">
                <ul
                  className="nav nav-segment nav-pills scrollbar-horizontal mb-7"
                  role="tablist"
                >
                  <li className="nav-item">
                    <a
                      className="nav-link active"
                      id="pills-one-code-features-example1-tab"
                      data-toggle="pill"
                      href="#pills-one-code-features-example1"
                      role="tab"
                      aria-controls="pills-one-code-features-example1"
                      aria-selected="true"
                    >
                      Recipe
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      id="pills-two-code-features-example1-tab"
                      data-toggle="pill"
                      href="#pills-two-code-features-example1"
                      role="tab"
                      aria-controls="pills-two-code-features-example1"
                      aria-selected="false"
                    >
                      Aisle
                    </a>
                  </li>
                </ul>
              </div>
              <div className="tab-content my-3">
                <div
                  className="tab-pane fade show active"
                  id="pills-one-code-features-example1"
                  role="tabpanel"
                  aria-labelledby="pills-one-code-features-example1-tab"
                >
                  <div className="col-sm-12">
                    {me.bookmarks.map((recipe) => {
                      return (
                        <>
                          <div className="h6">{recipe.name}</div>
                          <ul>
                            {recipe.ingredients.map((ing) => {
                              return <li>{ing.ingredient.name}</li>;
                            })}
                          </ul>
                        </>
                      );
                    })}
                  </div>
                </div>

                <div
                  className="tab-pane fade"
                  id="pills-two-code-features-example1"
                  role="tabpanel"
                  aria-labelledby="pills-two-code-features-example1-tab"
                >
                  <div className="col-sm-12">{groupContent}</div>
                </div>
              </div>
            </div>
            <div className="col-sm-3"></div>
          </div>
        </div>
      );
    }

    return (
      <div className="profile">
        <div className="container">
          <div className="row">
            <div className="col-md-12">{profileContent}</div>
          </div>
        </div>
      </div>
    );
  }
}

ShoppingList.propTypes = {
  getCurrentProfile: PropTypes.func.isRequired,
  me: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  me: state.me,
  auth: state.auth,
});

export default connect(mapStateToProps, { getCurrentProfile })(ShoppingList);
