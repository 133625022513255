import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import TextFieldGroup from "../common/TextFieldGroup";
import {
  updateRecipe,
  uploadRecipeImage,
  getRecipe,
} from "../../actions/recipeFormActions";
import { getCurrentProfile } from "../../actions/meActions";
import {
  getIngredients,
  addIngredient,
} from "../../actions/ingredientsFormActions";
import StepForm from "./StepForm";
import IngredientsForm from "./IngredientsForm";
import isEmpty from "../../validation/isEmpty";
import { withRouter } from "react-router-dom";
import Dropzone from "react-dropzone";
import Spinner from "../common/Spinner";
import { Link } from "react-router-dom";
import { encode } from "../../utils/urlEncode";

class UpdateRecipeForm extends Component {
  thumbsContainer = {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    marginTop: 16,
  };

  constructor(props) {
    super(props);

    this.state = {
      id: "",
      name: "",
      serves: "",
      preptime: "",
      steps: [],
      ingredientsList: [],
      comments: [],
      created: "",
      updated: "",
      author: {},
      img: {},
      likes: [],
      private: false,
      deleted: false,
      tags: [],

      files: [], //dropzone file store

      image: {}, //response from uploadImage

      //errors
      errors: {},

      //helper
      ingredientForm: [], // response from addIngredient
      recipeForm: {},
      instruction: "", //method
      ingredient: {}, // TypeAhead (either string or {})
      unit: "",
      amount: "",
      order: "",
    };

    this.onChange = this.onChange.bind(this);
    this.onChangeTypeAhead = this.onChangeTypeAhead.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onAddStep = this.onAddStep.bind(this);
    this.onRemoveStep = this.onRemoveStep.bind(this);
    this.onStepUp = this.onStepUp.bind(this);
    this.onStepDown = this.onStepDown.bind(this);

    this.onAddIngredient = this.onAddIngredient.bind(this);
    this.onRemoveIngredient = this.onRemoveIngredient.bind(this);
    this.onRemoveImage = this.onRemoveImage.bind(this);

    this.onUploadImage = this.onUploadImage.bind(this);
    this.onDrop = this.onDrop.bind(this);
  }

  componentDidMount() {
    // a recipe was passed in for us to edit.
    if (this.props.match.params.name) {
      this.props.getRecipe(this.props.match.params.name);
    }

    this.props.getIngredients();
    this.props.getCurrentProfile();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    console.log("props updated!!!", nextProps);
    //window.scrollTo(0, 0);

    if (nextProps.errors) {
      this.setState({ errors: nextProps.errors });
    }

    if (!isEmpty(nextProps.me)) {
      console.log("-- profile props updated!!!");

      this.setState(
        {
          author: nextProps.me.me,
        },
        () => {}
      );
    }

    if (
      !isEmpty(nextProps.recipeForm.image) &&
      !isEmpty(nextProps.recipeForm.image.image)
    ) {
      console.log("-- image props updated!!!");

      let newFile = {
        _id: nextProps.recipeForm.image.image._id,
        name: nextProps.recipeForm.image.image.name,
        url: nextProps.recipeForm.image.image.url,
      };

      if (this.state.files.length === 0) {
        this.setState(
          {
            files: [...this.state.files, newFile],
          },
          () => {}
        );
      } else if (
        !isEmpty(this.props.recipeForm.image) &&
        nextProps.recipeForm.image.image.name !==
          this.props.recipeForm.image.image.name
      ) {
        this.setState(
          {
            files: [...this.state.files, newFile],
          },
          () => {}
        );
      }
    }

    if (
      !isEmpty(nextProps.ingredientForm.ingredient) &&
      nextProps.ingredientForm.ingredient.name !==
        this.props.ingredientForm.ingredient.name
    ) {
      let newIngredient = nextProps.ingredientForm.ingredient;
      newIngredient.unit = this.state.unit;
      newIngredient.amount = this.state.amount;

      this.setState(
        {
          ingredient: {},
          unit: "",
          amount: "",
          errors: {},
          ingredientsList: [...this.state.ingredientsList, newIngredient],
        },
        () => {}
      );
    }

    if (
      !isEmpty(nextProps.recipeForm.recipeForm) &&
      nextProps.recipeForm.recipeForm._id !== this.state.id
    ) {
      console.log("-- recipe props updated!!!");
      console.log(nextProps.recipeForm.recipeForm._id);
      console.log(this.state.id);
      const recipe = nextProps.recipeForm.recipeForm;

      let imgFiles = [];

      for (var i = 0; i < recipe.img.length; i++) {
        if (!isEmpty(recipe.img[i])) {
          let newFile = {
            _id: recipe.img[i]._id,
            name: recipe.img[i].name,
            url: recipe.img[i].url,
          };

          imgFiles.push(newFile);
        }
      }

      let ingList = [];
      for (var il = 0; il < recipe.ingredients.length; il++) {
        if (!isEmpty(recipe.ingredients[il])) {
          let newIngredient = {};

          //newIngredient.ingredient = {};
          newIngredient._id = recipe.ingredients[il].ingredient._id;
          newIngredient.name = recipe.ingredients[il].ingredient.name;
          newIngredient.unit = recipe.ingredients[il].unit;
          newIngredient.amount = recipe.ingredients[il].amount;

          ingList.push(newIngredient);
        }
      }

      this.setState({
        id: recipe._id,
        name: !isEmpty(recipe.name) ? recipe.name : this.state.name,
        serves: !isEmpty(recipe.serves) ? recipe.serves : this.state.serves,
        preptime: !isEmpty(recipe.preptime)
          ? recipe.preptime
          : this.state.preptime,
        steps: !isEmpty(recipe.steps) ? [...recipe.steps] : this.state.steps,
        ingredientsList: [...ingList],
        comments: !isEmpty(recipe.comments)
          ? [...recipe.comments]
          : this.state.comments,
        created: !isEmpty(recipe.created) ? recipe.created : this.state.created,
        updated: !isEmpty(recipe.updated) ? recipe.updated : this.state.updated,
        author: !isEmpty(recipe.author) ? recipe.author : this.state.author,
        img: [...imgFiles],
        files: [...imgFiles], // referenced when we submit the update
        likes: !isEmpty(recipe.likes) ? recipe.likes : this.state.likes,
        private: !isEmpty(recipe.private) ? recipe.private : this.state.private,
        deleted: !isEmpty(recipe.deleted) ? recipe.deleted : this.state.deleted,
        tags: !isEmpty(recipe.tags) ? recipe.tags : this.state.tags,
      });
    }
  }

  onDrop(d) {
    const imgData = new FormData();
    imgData.append("file", d[0]);
    imgData.append("path", "recipes/");

    this.props.uploadRecipeImage(imgData, this.props.history);
  }

  onAddStep() {
    if (isEmpty(this.state.instruction)) return;

    let newStep = {
      step: this.state.instruction,
      order: this.state.steps.length + 1,
    };

    // this.resetStepOrder();

    this.setState({ steps: [...this.state.steps, newStep], instruction: "" });
  }

  onRemoveStep(index) {
    let updatedSteps = this.state.steps;
    updatedSteps.splice(index, 1);

    //this.resetStepOrder();

    this.setState({
      steps: [...updatedSteps],
    });
  }

  onStepUp(index) {
    // test if we can move this item up
    if (index <= 0) {
      return;
    }

    let updatedSteps = this.state.steps;
    updatedSteps.splice(index - 1, 0, updatedSteps.splice(index, 1)[0]);

    //this.resetStepOrder();

    this.setState({
      steps: [...updatedSteps],
    });
  }

  onStepDown(index) {
    // test if we can move this item down
    if (index >= this.state.steps.length) {
      return;
    }

    let updatedSteps = this.state.steps;
    updatedSteps.splice(index + 1, 0, updatedSteps.splice(index, 1)[0]);

    //this.resetStepOrder();

    this.setState({
      steps: [...updatedSteps],
    });
  }

  //TODO: not sure why this is needed... need to review and add comments
  // resetStepOrder() {
  //   for (var i = 0; i < this.state.steps.length; i++) {
  //     this.state.steps[i].order = i + 1;
  //   }
  // }

  onAddIngredient() {
    if (isEmpty(this.state.ingredient) || isEmpty(this.state.amount)) {
      var errors = {};
      errors.ingredientForm = "Missing required value: amount or ingredient.";
      this.setState({ errors: errors });
      return;
    }

    this.props.addIngredient(this.state.ingredient, this.props.history);
  }

  onRemoveIngredient(index) {
    console.log("ON REMOVE", index, this.state.ingredientsList);
    let updatedIngredients = this.state.ingredientsList;
    updatedIngredients.splice(index, 1);

    this.setState({
      ingredientsList: [...updatedIngredients],
    });
  }

  onRemoveImage(index) {
    console.log("ON REMOVE", index, this.state.files);
    let updatedFiles = this.state.files;
    updatedFiles.splice(index, 1);

    this.setState({
      files: [...updatedFiles],
    });
  }

  onSubmit(e) {
    e.preventDefault();

    // incorporate files (images from dropzone)
    // inforporate ingredients

    let imgFiles = [];
    for (const imgFile of this.state.files) {
      if (!isEmpty(imgFile) && !isEmpty(imgFile._id)) {
        imgFiles.push(imgFile._id);
      }
    }

    let fullIngs = [];
    for (const ingIndex of this.state.ingredientsList) {
      if (!isEmpty(ingIndex)) {
        let fullIng = {
          amount: ingIndex.amount,
          ingredient: ingIndex._id,
          unit: ingIndex.unit,
        };
        fullIngs.push(fullIng);
      }
    }

    const updatedRecipe = {
      id: isEmpty(this.state.id) ? this.state.id : null,
      name: this.state.name,
      serves: this.state.serves,
      preptime: this.state.preptime,
      steps: this.state.steps,
      ingredients: fullIngs,
      comments: this.state.comments,
      created: this.state.created,
      updated: this.state.updated,
      author: this.state.author,
      img: imgFiles,
      likes: this.state.likes,
      private: this.state.private,
      deleted: this.state.deleted,
      tags: this.state.tags,
    };

    this.props.updateRecipe(updatedRecipe, this.props.history);
  }

  onUploadImage = (e) => {
    e.preventDefault();

    const imgData = new FormData();
    imgData.append("file", e.target.files[0]);
    imgData.append("path", "recipes/");

    this.props.uploadRecipeImage(imgData);
  };

  onChange(e) {
    const value =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;

    this.setState({
      [e.target.name]: value,
    });
  }

  onChangeTypeAhead(e) {
    //todo pass in AUTHOR object ID

    let newingredient = this.state.ingredient;
    newingredient["location"] = "UNKNOWN";

    if (Array.isArray(e) && e.length > 0) {
      newingredient.name = e[0].name;
      this.setState({
        ingredient: newingredient,
      });
    } else {
      newingredient.name = e;
      this.setState({
        ingredient: newingredient,
      });
    }
  }

  render() {
    let thumb = {
      display: "inline-flex",
      borderRadius: 2,
      border: "1px solid #eaeaea",
      marginBottom: 8,
      marginRight: 8,
      width: 100,
      height: 100,
      padding: 4,
      boxSizing: "border-box",
    };

    let thumbInner = {
      display: "flex",
      minWidth: 0,
      overflow: "hidden",
    };

    let img = {
      display: "block",
      width: "auto",
      height: "100%",
    };

    const { errors, files } = this.state;
    const { loading, ingredients } = this.props.ingredientForm;
    const { me } = this.props.me;

    if (isEmpty(ingredients) || loading) {
      return <Spinner />;
    }

    let metaURL = "https://socialspoon.org/recipes/" + encode(this.state.name);

    let avatarinfo = "";
    if (!isEmpty(me)) {
      avatarinfo = (
        <div className="">
          {!isEmpty(me.image) && (
            <div className="avatar avatar-lg mr-2 mt-n3">
              <Link to={`/profiles/${me.username}`} className="">
                <img
                  src={me.image.url}
                  alt="..."
                  className="avatar-img rounded-circle"
                />
              </Link>
            </div>
          )}

          <Link to={`/profiles/${me.username}`} className="">
            {me.username}
          </Link>
        </div>
      );
    }

    let filesul = files.map((file, index) => (
      <div style={thumb} key={file.name}>
        <div style={thumbInner}>
          <img src={file.url} alt="" style={img}></img>
        </div>
        <div className="row">
          <a href="#" alt="" onClick={(param) => this.onRemoveImage(index)}>
            <span className="text-danger">
              <i className="fas fa-trash-alt"></i>
            </span>
          </a>
        </div>
      </div>
    ));

    return (
      <div className="container">
        {/* <div className="row">
          <div className="col-md-12">
            <h1 className="display-4 text-center site-title">
              I'm sure it tastes great.
            </h1>
            <p className="lead text-center">
              Fill in your recipe details using the form below.
            </p>
          </div>
        </div> */}

        <form noValidate onSubmit={this.onSubmit}>
          <h4 className="mb-2">Basic Info.</h4>
          <div className="form-row">
            <div className="col-8">
              <TextFieldGroup
                placeholder="Mary Jane's Brownies"
                name="name"
                value={this.state.name}
                onChange={this.onChange}
                error={errors.name}
                label="Recipe Name"
                info={metaURL}
              />
            </div>
            <div className="col-4">
              {/* <TextFieldGroup
                placeholder={avatarinfo}
                name="createdbynotused"
                value={avatarinfo}
                onChange={this.onChange}
                error={errors.name}
                label="Created By"
                info={
                  <small className="text-muted mb-0 ml-auto">
                    <span></span>
                    <Moment date={new Date()}></Moment>
                  </small>
                }
              /> */}

              <label className="ml-3 col-form-label">Created By</label>
              <div className="col-sm-10">
                {avatarinfo}
                {/* <small className="form-text text-muted">
                  <Moment date={new Date()}></Moment>
                </small> */}
              </div>
            </div>
          </div>
          {/* <TextFieldGroup
            placeholder={metaURL}
            name="link"
            disabled={true}
            onChange={this.onChange}
            value={metaURL}
          /> */}
          <div className="form-row">
            <div className="form-group col-4">
              <TextFieldGroup
                placeholder="4"
                name="serves"
                value={this.state.serves}
                onChange={this.onChange}
                error={errors.serves}
                label="Serves"
              />
            </div>
            <div className="form-group col-4">
              <TextFieldGroup
                placeholder="Prep Time (in minutes)"
                name="preptime"
                value={this.state.preptime}
                onChange={this.onChange}
                error={errors.preptime}
                label="Preptime"
              />
            </div>
            <div className="form-group col-4">
              <TextFieldGroup
                placeholder="0"
                name="ingredientscount"
                value={this.state.ingredientsList.length}
                onChange={this.onChange}
                disabled={true}
                label="Ingredients"
              />
            </div>
          </div>

          <div className="form-row">
            <div className="form-group col-12">
              <Dropzone onDrop={this.onDrop}>
                {({ getRootProps, getInputProps }) => (
                  <section className="dropcontainer">
                    <div {...getRootProps({ className: "dropzone" })}>
                      <input {...getInputProps()} />
                      <p>
                        Drag 'n' drop some pictures of the recipe here, or click
                        to select files
                      </p>
                    </div>
                    <aside>
                      <ul>{filesul}</ul>
                    </aside>
                  </section>
                )}
              </Dropzone>
            </div>
          </div>

          <IngredientsForm
            name="ingredientForm"
            ingredients={this.state.ingredientsList}
            options={this.props.ingredientForm.ingredients}
            ingredient={this.state.ingredient}
            unit={this.state.unit}
            amount={this.state.amount}
            onChange={this.onChange}
            onChangeTA={this.onChangeTypeAhead}
            onAddIngredient={this.onAddIngredient}
            onRemoveIngredient={this.onRemoveIngredient}
            error={errors.ingredients}
          />
          <StepForm
            name="steps"
            steps={this.state.steps}
            instruction={this.state.instruction}
            onChange={this.onChange}
            onAddStep={this.onAddStep}
            onRemoveStep={this.onRemoveStep}
            onStepUp={this.onStepUp}
            onStepDown={this.onStepDown}
            error={errors.steps}
          />

          <div className="form-row">
            <div className="form-group col-12">
              <input
                type="submit"
                value="Submit"
                className="btn btn-primary btn-wide float-right lift mt-4"
              />
            </div>
          </div>
        </form>
      </div>
    );
  }
}

UpdateRecipeForm.propTypes = {
  updateRecipe: PropTypes.func.isRequired,
  uploadRecipeImage: PropTypes.func.isRequired,
  getIngredients: PropTypes.func.isRequired,
  addIngredient: PropTypes.func.isRequired,
  getRecipe: PropTypes.func.isRequired,
  ingredientForm: PropTypes.object.isRequired,

  errors: PropTypes.object.isRequired,
  getCurrentProfile: PropTypes.func.isRequired,
  me: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  me: state.me,
  recipeForm: state.recipeForm,
  ingredientForm: state.ingredientForm,
  errors: state.errors,
});

export default connect(mapStateToProps, {
  updateRecipe,
  uploadRecipeImage,
  getIngredients,
  addIngredient,
  getCurrentProfile,
  getRecipe,
})(withRouter(UpdateRecipeForm));
