import React, { Component } from "react";
import TextAreaFieldGroup from "../common/TextAreaFieldGroup";
import isEmpty from "../../validation/isEmpty";

class StepForm extends Component {
  render() {
    let { steps, error } = this.props;

    let stepsContent = [];
    if (!isEmpty(steps)) {
      steps.map((step, index) => {
        return stepsContent.push(
          <div className="d-flex" key={step._id}>
            <span className="badge badge-lg badge-rounded-circle badge-primary">
              <span>{index + 1}</span>
            </span>
            <div className="ml-3 mb-2">
              <p className="">
                {step.step}{" "}
                <a href="#" onClick={(param) => this.props.onRemoveStep(index)}>
                  <span className="ml-4 text-danger">
                    <i className="fas fa-trash-alt"></i>
                  </span>
                </a>
                <a href="#" onClick={(param) => this.props.onStepUp(index)}>
                  <span className="ml-4 text-primary">
                    <i className="fas fa-angle-up"></i>
                  </span>
                </a>
                <a href="#" onClick={(param) => this.props.onStepDown(index)}>
                  <span className="ml-4 text-primary">
                    <i className="fas fa-angle-down"></i>
                  </span>
                </a>
              </p>
            </div>
          </div>
        );
      });
    }

    return (
      <div>
        <h4 className="mb-3">Method</h4>
        {error && <div className="text-danger">{error}</div>}
        {stepsContent}
        <div className="form-row">
          <div className="form-group col-12">
            <label htmlFor="instruction">
              Step {this.props.steps.length + 1}
            </label>
            <TextAreaFieldGroup
              placeholder="Cream the butter and sugar until light and fluffy. Beat in the eggs one at a time. In a seperate bowl, sift together the flower, baking soda and salt."
              name="instruction"
              label="Instruction"
              onChange={this.props.onChange}
              value={this.props.instruction}
            ></TextAreaFieldGroup>
          </div>
        </div>
        <div className="form-row form-group">
          <div className="col-4"></div>
          <div className="col-8">
            <button
              type="button"
              className="btn btn-primary float-right lift"
              onClick={this.props.onAddStep}
            >
              <span className="mr-3">Add Step</span>
              <i className="fas fa-star-of-life" />
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default StepForm;
