import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  getProfileByUsername,
  getProfileRecipes,
} from "../../actions/profileActions";
import { getCurrentProfile } from "../../actions/meActions";
import Spinner from "../common/Spinner";
import isEmpty from "../../validation/isEmpty";
import RecipeFeed from "../recipe/RecipeFeed";
import ShoppingCard from "../recipe/ShoppingCard";
import Moment from "react-moment";
import { Link } from "react-router-dom";

class ViewProfile extends Component {
  componentDidMount() {
    if (this.props.match.params.username) {
      this.props.getProfileByUsername(this.props.match.params.username);
      this.props.getProfileRecipes(this.props.match.params.username);
      this.props.getCurrentProfile();

      window.scrollTo(0, 0);
    }
  }

  componentDidUpdate(nextProps) {
    if (nextProps.location.pathname !== this.props.location.pathname) {
      this.props.getProfileByUsername(this.props.match.params.username);
      this.props.getProfileRecipes(this.props.match.params.username);
      window.scrollTo(0, 0);
    }
  }
  //  todo: replace all UNSAFE_componentWillReceiveProps with componentnDidUpdate... seems to do the same thing
  // UNSAFE_componentWillReceiveProps(nextProps) {
  //   if (nextProps.profile.username !== this.props.profile.username) {
  //     this.props.getProfileByUsername(this.props.match.params.username);
  //     this.props.getProfileRecipes(this.props.match.params.username);
  //     window.scrollTo(0, 0);
  //   }
  // }

  render() {
    const { profile, profilerecipes, loading } = this.props.profile;
    const { me } = this.props.me;

    let recipeContent;
    let profileContent;
    let bookmarkContent;
    let shoppingContent;
    //let profilerecipesContent;

    shoppingContent = (
      <>
        <div className="row">
          <h3 className="m-3">Grocery List:</h3>
        </div>
        <ShoppingCard></ShoppingCard>
      </>
    );

    if (!isEmpty(profile) && !isEmpty(profile.bookmarks)) {
      bookmarkContent = (
        <>
          <div className="row">
            <h3 className="m-3">
              {/* {!isEmpty(me) &&
                !isEmpty(profile) &&
                me.user._id === profile.user._id && (
                  <Link to="/update-recipe" className="lift">
                    <i className="fas fa-plus-circle text-primary mr-2" />
                  </Link>
                )} */}
              Bookmarks:
            </h3>
          </div>
          <div className="card-columns card-columns-large">
            <RecipeFeed
              recipes={profile.bookmarks[0].recipes}
              products={profile.products}
              mini={true}
            />
          </div>
        </>
      );
    }

    if ((isEmpty(profile) && isEmpty(profilerecipes)) || loading) {
    } else {
      if (!isEmpty(profilerecipes)) {
        recipeContent = (
          <>
            <div className="row">
              <h3 className="m-3">
                {!isEmpty(me) &&
                  !isEmpty(profile) &&
                  me.user._id === profile.user._id && (
                    <Link to="/update-recipe" className="lift">
                      <i className="fas fa-plus-circle text-primary mr-2" />
                    </Link>
                  )}
                Recipes:
              </h3>
            </div>
            <div className="card-columns card-columns-large">
              <RecipeFeed
                recipes={profilerecipes}
                products={profile.products}
                mini={true}
              />
            </div>
          </>
        );
      }

      let friendsList = null;
      if (!isEmpty(profile.friends)) {
        friendsList = profile.friends.map((friend, index) => (
          <div className="avatar avatar-xl img-thumbnail rounded-circle mx-1 my-1">
            <Link to={`/profiles/${friend.username}`}>
              <img
                src={friend.image.url}
                alt="..."
                className="avatar-img rounded-circle"
              />
            </Link>
          </div>
        ));
      } else {
        friendsList = "";
      }

      // Check to see if the user has setup a profile.
      if (!isEmpty(profile)) {
        profileContent = (
          <div>
            <div className="card shadow-light-lg">
              {!isEmpty(profile.image) && (
                <div className="mx-auto d-flex justify-content-center">
                  <Link to={`/profiles/${profile.username}`} className="">
                    <img
                      className="profile-image img-thumbnail rounded-circle"
                      src={profile.image.url}
                      alt=""
                    />
                  </Link>
                </div>
              )}
              {/* <div className="display-4 text-center site-title mb-4">
                {profile.name}
              </div> */}

              {!isEmpty(me) &&
                !isEmpty(profile) &&
                me.user._id === profile.user._id && (
                  <Link
                    className="btn btn-outline-secondary btn-block my-2 lift"
                    to="/update-profile"
                  >
                    Edit Profile <i className="far fa-edit text-warning"></i>
                  </Link>
                )}
              {!isEmpty(me) && isEmpty(profile) && (
                <Link
                  to="/update-profile"
                  className="btn btn-block btn-primary mt-4"
                >
                  Create Profile
                </Link>
              )}

              <div className="bg-info px-4 py-1">
                <small className="mb-0 text-muted">Statistics</small>
              </div>
              <ul className="list-group list-group-flush">
                <li
                  className="list-group-item d-flex justify-content-between align-items-center"
                  key={"ausdd"}
                >
                  <span className="badge badge-primary badge-pill">
                    Username
                  </span>

                  <span>{profile.username}</span>
                </li>
                <li
                  className="list-group-item d-flex justify-content-between align-items-center"
                  key={"ausdd"}
                >
                  <span className="badge badge-primary badge-pill">
                    Followers
                  </span>

                  <span>{profile.friends.length}</span>
                </li>
                {!isEmpty(profilerecipes) && (
                  <li
                    className="list-group-item d-flex justify-content-between align-items-center"
                    key={"ausd"}
                  >
                    <span className="badge badge-primary badge-pill">
                      Recipes
                    </span>

                    <span>
                      {profilerecipes.length}{" "}
                      {!isEmpty(me) &&
                        !isEmpty(profile) &&
                        me.user._id === profile.user._id && (
                          <Link to="/update-recipe" className="lift">
                            <i className="fas fa-plus-circle text-primary" />
                          </Link>
                        )}
                    </span>
                  </li>
                )}

                <li
                  className="list-group-item d-flex justify-content-between align-items-center"
                  key={"ausd"}
                >
                  <span className="badge badge-primary badge-pill">
                    Join Date
                  </span>

                  <span>
                    <Moment format="MM/DD/YYYY">{profile.created}</Moment>
                  </span>
                </li>
              </ul>
              <div className="bg-info px-4 py-1">
                <small className="mb-0 text-muted">About</small>
              </div>
              <ul className="list-group list-group-flush">
                <li
                  className="list-group-item d-flex justify-content-between align-items-center"
                  key={"asdf"}
                >
                  <span>{profile.bio}</span>
                </li>
              </ul>
              <div className="bg-info px-4 py-1">
                <small className="mb-0 text-muted">Followed By</small>
              </div>

              <div className="col d-flex flex-wrap mt-2 p-0">{friendsList}</div>
            </div>
          </div>
        );
      }
    }

    return (
      <div className="profile">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-3 sidebar">
              <div className="sidebar-sticky">
                {profileContent} {shoppingContent}
              </div>
            </div>

            <div className="col-md-9">
              {recipeContent}

              <hr className="line-style" />

              {bookmarkContent}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

ViewProfile.propTypes = {
  getProfileByUsername: PropTypes.func.isRequired,
  getProfileRecipes: PropTypes.func.isRequired,
  getCurrentProfile: PropTypes.func.isRequired,
  profile: PropTypes.object.isRequired,
  profilerecipes: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  me: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  profilerecipes: state.profilerecipes,
  profile: state.profile,
  auth: state.auth,
  me: state.me,
});

export default connect(mapStateToProps, {
  getProfileByUsername,
  getProfileRecipes,
  getCurrentProfile,
})(ViewProfile);
