import axios from "axios";

import {
  GET_CURRENT_PROFILE,
  ME_LOADING,
  CLEAR_CURRENT_PROFILE,
  UPLOAD_PROFILE_IMAGE,
  SAVE_RECIPE,
  REMOVE_RECIPE,
  GET_ERRORS,
} from "./types";

export const getCurrentProfile = () => (dispatch) => {
  dispatch(setProfileLoading());

  axios
    .get("/api/profiles")
    .then((res) => dispatch({ type: GET_CURRENT_PROFILE, payload: res.data }))
    .catch((err) => dispatch({ type: GET_ERRORS, payload: err.response.data }));
};

export const updateProfile = (profileData, history) => (dispatch) => {
  axios
    .post("/api/profiles", profileData)
    .then((res) => history.push("/dashboard"))
    .catch((err) => dispatch({ type: GET_ERRORS, payload: err.response.data }));
};

export const uploadProfileImage = (profileData, history) => (dispatch) => {
  dispatch(setProfileLoading());

  axios
    .post("/api/images", profileData)
    .then((res) => dispatch({ type: UPLOAD_PROFILE_IMAGE, payload: res.data }))
    .catch((err) => dispatch({ type: GET_ERRORS, payload: err.response.data }));
};

export const saveRecipe = (saveData, history) => (dispatch) => {
  dispatch(setProfileLoading());

  axios
    .post("/api/profiles/bookmark/", saveData)
    .then((res) => dispatch({ type: SAVE_RECIPE, payload: res.data }))
    .catch((err) => {
      dispatch({ type: GET_ERRORS, payload: err.response.data });
    });
};

export const removeRecipe = (removeData, history) => (dispatch) => {
  dispatch(setProfileLoading());

  axios
    .delete("/api/profiles/bookmark/", { data: removeData })
    .then((res) => dispatch({ type: REMOVE_RECIPE, payload: res.data }))
    .catch((err) => dispatch({ type: GET_ERRORS, payload: err.response.data }));
};

export const setProfileLoading = () => {
  return {
    type: ME_LOADING,
  };
};

export const clearCurrentProfile = () => {
  return {
    type: CLEAR_CURRENT_PROFILE,
  };
};
