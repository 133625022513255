import axios from "axios";

import {
  UPDATE_RECIPE,
  GET_RECIPE,
  UPLOAD_RECIPE_IMAGE,
  RECIPE_LOADING,
  ADD_COMMENT,
  GET_ERRORS,
} from "./types";

export const updateRecipe = (recipeData, history) => (dispatch) => {
  //todo: send to updated recipe... thought it was already doing that.... check.
  axios
    .post("/api/recipes", recipeData)
    .then((res) => dispatch({ type: UPDATE_RECIPE, payload: res.data }))
    .then((res) => history.push("/"))
    .catch((err) => dispatch({ type: GET_ERRORS, payload: err.response.data }));
};

export const uploadRecipeImage = (recipeData, history) => (dispatch) => {
  axios
    .post("/api/images", recipeData)
    .then((res) => dispatch({ type: UPLOAD_RECIPE_IMAGE, payload: res.data }))
    .catch((err) => dispatch({ type: GET_ERRORS, payload: err.response.data }));
};

export const getRecipe = (recipeData, history) => (dispatch) => {
  dispatch(setRecipeLoading());
  axios
    .get(`/api/recipes/${recipeData}`)
    .then((res) => dispatch({ type: GET_RECIPE, payload: res.data }))
    .catch((err) => dispatch({ type: GET_ERRORS, payload: err.response.data }));
};

export const addComment = (id, recipeData, history) => (dispatch) => {
  axios
    .post(`/api/recipes/comment/${id}`, recipeData)
    .then((res) => dispatch({ type: ADD_COMMENT, payload: res.data }))
    .catch((err) => dispatch({ type: GET_ERRORS, payload: err.response.data }));
};

export const setRecipeLoading = () => {
  return {
    type: RECIPE_LOADING,
  };
};
