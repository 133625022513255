const urlEncode = (value) => {
  var encoded = encodeURIComponent(value.toLowerCase());
  return encoded.replace(/%20/g, "-");
};

const urlDecode = (value) => {
  var replaced = value.replace(/-/g, "%20");
  return decodeURIComponent(replaced);
};

module.exports.encode = urlEncode;
module.exports.decode = urlDecode;
