// provide a Me object that represents the currently logged in user.
const isRecipeSavedInMe = (me, recipeId) => {
  if (me !== undefined || me !== null) {
    if (
      me.bookmarks !== undefined ||
      me.bookmarks !== null ||
      (typeof me.bookmarks === "object" &&
        Object.keys(me.bookmarks).length !== 0)
    ) {
      if (
        me.bookmarks.recipes !== undefined ||
        me.bookmarks.recipes !== null ||
        (typeof me.bookmarks.recipes === "object" &&
          Object.keys(me.bookmarks.recipes).length !== 0)
      ) {
        for (var i = 0; i < me.bookmarks.length; i++) {
          for (var j = 0; j < me.bookmarks[i].recipes.length; j++) {
            console.log("COMPARE", me.bookmarks[i].recipes[j] + " " + recipeId);
            if (me.bookmarks[i].recipes[j] == recipeId) {
              console.log("RTURN TRUE");
              return true;
            }
          }
        }
      }
    }
  }
  console.log("RTURN FALSE");

  return false;
};

export default isRecipeSavedInMe;
