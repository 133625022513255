import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";

const Uploader = ({ name, value, label, error, info, onChange, disabled }) => {
  return (
    <div className="form-group">
      <label htmlFor={label}>{label}</label>
      <div className="input-group">
        <span className="input-group-btn">
          <span className="btn btn-default">
            <input
              type="file"
              name={name}
              value={value}
              onChange={onChange}
              className={classnames("form-control-file", {
                "is-invalid": error,
              })}
              id={label}
            ></input>
          </span>
        </span>
      </div>
      {info && <small className="form-text text-muted">{info}</small>}
      {error && <div className="invalid-feedback">{error}</div>}
    </div>
  );
};

Uploader.propTypes = {
  name: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  info: PropTypes.string,
  error: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.string,
};

Uploader.defaultProps = {};

export default Uploader;
