import {
  GET_INGREDIENT,
  GET_INGREDIENTS,
  INGREDIENT_LOADING,
  GET_INGREDIENT_RECIPES,
  GET_INGREDIENT_META,
} from "../actions/types";

const initialState = {
  ingredient: null,
  ingredients: null,
  recipes: null,

  loading: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case INGREDIENT_LOADING:
      return { ...state, loading: true };
    case GET_INGREDIENT:
      return { ...state, ingredient: action.payload, loading: false };
    case GET_INGREDIENTS:
      return { ...state, ingredients: action.payload, loading: false };
    case GET_INGREDIENT_RECIPES:
      return { ...state, recipes: action.payload, loading: false };
    case GET_INGREDIENT_META:
      return { ...state, meta: action.payload, loading: false };
    default:
      return state;
  }
}
