import React, { Component } from "react";
import { Link } from "react-router-dom";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";

class Landing extends Component {
  // componentDidMount() {
  //   if (this.props.auth.isAuthenticated) {
  //     this.props.history.push("/");
  //     window.scrollTo(0, 0);
  //   }
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div className="container">
        {/* <div id="myCarousel" className="carousel slide" data-ride="carousel">
          <div className="carousel-inner">
            <div className="carousel-item active">
              <img
                className="d-block w-100 img-fluid recipe-feature-image"
                src="https://res.cloudinary.com/htypemczi/image/upload/v1587774908/site/smartmockups_k9ew4m9i.jpg"
                alt="First slide"
              />
              <div className="container">
                <div className="carousel-caption text-left">
                  <h1>Easy.</h1>
                  <p>
                    Our crowdsourced recipes are easy to understand, and even
                    easier to prepare.
                  </p>
                  <p>
                    <Link to="/register" className="btn btn-lg btn-info">
                      Sign up today
                    </Link>
                  </p>
                </div>
              </div>
            </div>
            <div className="carousel-item">
              <img
                className="d-block w-100 img-fluid recipe-feature-image"
                src="https://res.cloudinary.com/htypemczi/image/upload/v1587777338/site/Screen_Shot_2020-04-24_at_8.15.12_PM.png"
                alt="Second slide"
              />
              <div className="container">
                <div className="carousel-caption">
                  <p>
                    <Link to="/" className="btn btn-lg btn-info">
                      Browse recipes
                    </Link>
                  </p>
                </div>
              </div>
            </div>
            <div className="carousel-item">
              <img
                className="d-block w-100 img-fluid recipe-feature-image"
                src="https://res.cloudinary.com/htypemczi/image/upload/v1587777122/site/Screen_Shot_2020-04-24_at_8.11.28_PM.png"
                alt="Third slide"
              />
              <div className="container">
                <div className="carousel-caption text-left">
                  <h1>Social.</h1>
                  <p>
                    We are a social media platform dedicated to sharing recipes.
                  </p>
                  <p>
                    <Link to="/" className="btn btn-lg btn-info">
                      Browse recipes
                    </Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <a
            className="carousel-control-prev"
            href="#myCarousel"
            role="button"
            data-slide="prev"
          >
            <span
              className="carousel-control-prev-icon"
              aria-hidden="true"
            ></span>
            <span className="sr-only">Previous</span>
          </a>
          <a
            className="carousel-control-next"
            href="#myCarousel"
            role="button"
            data-slide="next"
          >
            <span
              className="carousel-control-next-icon"
              aria-hidden="true"
            ></span>
            <span className="sr-only">Next</span>
          </a>
        </div> */}

        {/* <div className="container marketing my-4">
          <div className="row">
            <div className="col-lg-4">
              <img
                className="img-thumbnail img-fluid"
                src="https://res.cloudinary.com/htypemczi/image/upload/v1587778315/site/Screen_Shot_2020-04-24_at_8.30.36_PM.png"
                alt="Generic placeholder"
              />

              <h2 className="site-title display-2 mb-4">Curate</h2>
              <ul className="list-unstyled mt-1 mb-2">
                <li>Catalog unlimited recipes</li>
                <li>Enter recipes quickly</li>
                <li>Organize recipes easily</li>
                <li></li>
              </ul>
               <p>
                <a className="btn btn-secondary" href="#" role="button">
                  View details &raquo;
                </a>
              </p> 
            </div>
            <div className="col-lg-4">
              <img
                className="img-thumbnail img-fluid"
                src="https://res.cloudinary.com/htypemczi/image/upload/v1587777122/site/Screen_Shot_2020-04-24_at_8.11.28_PM.pngdata:image/gif;base64,R0lGODlhAQABAIAAAHd3dwAAACH5BAAAAAAALAAAAAABAAEAAAICRAEAOw=="
                alt="Generic placeholder"
              />

              <h2 className="site-title display-2 mb-4">Plan</h2>
              <ul className="list-unstyled mt-1 mb-2">
                <li>Build weekly meal plans</li>
                <li>Generate shopping lists</li>
                <li>Search by ingredients</li>
                <li></li>
              </ul>
               <p>
                <a className="btn btn-secondary" href="#" role="button">
                  View details &raquo;
                </a>
              </p> 
            </div>
            <div className="col-lg-4">
              <img
                className="img-thumbnail img-fluid"
                src="https://res.cloudinary.com/htypemczi/image/upload/v1587777338/site/Screen_Shot_2020-04-24_at_8.15.12_PM.png"
                alt="Generic placeholder"
              />

              <h2 className="site-title display-2 mb-4">Discover</h2>
              <ul className="list-unstyled mt-1 mb-2">
                <li>Share recipes on your profile</li>
                <li>Focus on food</li>
                <li></li>
                <li></li>
              </ul>
             <p>
                <a className="btn btn-secondary" href="#" role="button">
                  View details &raquo;
                </a>
              </p> 
            </div>
          </div>
        </div> */}

        <div className="row">
          <div className="col-md-5 mt-5">
            <h2 className="site-title">
              <span className="text-muted">Social media </span>
              with a focus on cooking.
            </h2>
            <p className="lead">
              No obtrusive advertisments. No unneccessary life updates. The
              Social Spoon is a platform with a singular focus: cooking.
            </p>
            <p className="pb-4">
              <Link className="btn btn-primary btn-wide lift" to="/register">
                Not a member? Sign up{" "}
              </Link>
            </p>
          </div>
          {/* <div className="col-md-8">
            <img
              className="featurette-image img-fluid mx-auto"
              src="https://res.cloudinary.com/htypemczi/image/upload/f_auto/v1587778315/site/Screen_Shot_2020-04-24_at_8.30.36_PM.png"
              alt="Generic placeholder"
            />
          </div> */}
        </div>

        <hr className="featurette-divider" />

        <div className="row m-4">
          <div className="col-md-12">
            <h2 className="site-title text-center pb-2">Why Sign Up?</h2>
          </div>
        </div>
        <div className="card-deck m-4">
          <div className="card text-center">
            <div className="card-body bg-info p-4">
              <i className="fas fa-search fa-3x text-primary p-4"></i>
              <h4 className="recipe-title text-gray">Try Something New</h4>
              <p className="text-muted">
                Browse through hundreds of recipes submitted by our community
              </p>
            </div>
          </div>
          <div className="card text-center">
            <div className="card-body bg-info p-4">
              <i class="fas fa-list-ol fa-3x text-primary p-4"></i>
              <h4 className="recipe-title text-gray">
                Organize Your Meal Plan
              </h4>
              <p className="text-muted">
                Create shopping lists and plan for upcoming meals
              </p>
            </div>
          </div>
          <div className="card text-center">
            <div className="card-body bg-info p-4">
              <i class="fas fa-carrot fa-3x text-primary p-4"></i>
              <h4 className="recipe-title text-gray">Search by Ingredient</h4>
              <p className="text-muted">
                Find recipes using the ingredients you have on hand
              </p>
            </div>
          </div>
        </div>
        <div className="row m-4 p-5">
          <div className="col-md-12 text-center">
            <Link className="btn btn-primary btn-wide lift" to="/register">
              Not a member? Sign up{" "}
            </Link>
          </div>
        </div>
        <div className="row p-5 bg-info">
          <div className="col-md-12">
            <h2 className="site-title text-center pb-2">Recent Recipes</h2>
          </div>
        </div>

        {/* <hr className="featurette-divider" />

        <div className="row m-2">
          <div className="col-md-7">
            <h2 className="site-title">
              And lastly, this one.{" "}
              <span className="text-muted">Checkmate.</span>
            </h2>
            <p className="lead">
              Donec ullamcorper nulla non metus auctor fringilla. Vestibulum id
              ligula porta felis euismod semper. Praesent commodo cursus magna,
              vel scelerisque nisl consectetur. Fusce dapibus, tellus ac cursus
              commodo.
            </p>
          </div>
          <div className="col-md-5">
            <img
              className="featurette-image img-fluid mx-auto"
              src="data:image/gif;base64,R0lGODlhAQABAIAAAHd3dwAAACH5BAAAAAAALAAAAAABAAEAAAICRAEAOw=="
              alt="Generic"
            />
          </div>
        </div> */}

        {/* // <div classNameName="landing">
      //   <div classNameName="dark-overlay landing-inner">
      //     <div classNameName="container">
      //       <div classNameName="row">
      //         <div classNameName="col text-center">
      //           <h1 classNameName="site-title display-4 mb-4 text-light">
      //             The Social Spoon
      //           </h1>
      //         </div>
      //       </div>
      //       <div classNameName="row mb-3">
      //         <div classNameName="col-12">
      //            <p classNameName="lead">
      //             Discover recipes and plan weekly meals with ease.
      //           </p> 

      //           <div classNameName="card-deck text-center">
      //             <div classNameName="card mb-2 box-shadow">
      //               <div classNameName="card-header">
      //                 <h4 classNameName="my-0 font-weight-normal">Curate</h4>
      //               </div>
      //               <div classNameName="card-body">
      //                 <ul classNameName="list-unstyled mt-1 mb-2">
      //                   <li>Catalog unlimited recipes</li>
      //                   <li>Enter recipes quickly</li>
      //                   <li>Organize recipes easily</li>
      //                   <li></li>
      //                 </ul>
      //               </div>
      //             </div>
      //             <div classNameName="card mb-2 box-shadow">
      //               <div classNameName="card-header">
      //                 <h4 classNameName="my-0 font-weight-normal">Plan</h4>
      //               </div>
      //               <div classNameName="card-body">
      //                 <ul classNameName="list-unstyled mt-1 mb-2">
      //                   <li>Build weekly meal plans</li>
      //                   <li>Generate shopping lists</li>
      //                   <li>Search by ingredients</li>
      //                   <li></li>
      //                 </ul>
      //               </div>
      //             </div>
      //             <div classNameName="card mb-2 box-shadow">
      //               <div classNameName="card-header">
      //                 <h4 classNameName="my-0 font-weight-normal">Discover</h4>
      //               </div>
      //               <div classNameName="card-body">
      //                 <ul classNameName="list-unstyled mt-1 mb-2">
      //                   <li>Share recipes on your profile</li>
      //                   <li>Focus on food</li>
      //                   <li></li>
      //                   <li></li>
      //                 </ul>
      //               </div>
      //             </div>
      //           </div>
      //         </div>
      //       </div>
      //       <div classNameName="row mb-5 text-center">
      //         <div classNameName="col-12">
      //           <Link to="/register" classNameName="btn btn-lg btn-info mr-2">
      //             Sign Up For Free
      //           </Link>
      //           <Link to="/login" classNameName="btn btn-lg btn-light">
      //             Login
      //           </Link>
      //         </div>
      //       </div>
      //     </div>
      //   </div>
      // </div> */}
      </div>
    );
  }
}

Landing.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(Landing);
