import React, { Component } from "react";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";

class Legal extends Component {
  render() {
    return (
      <div className="container my-3">
        <div className="accordion" id="accordionExample">
          <div className="card">
            <div className="card-header" id="headingOne">
              <h2 className="mb-0">
                <button
                  className="btn btn-link"
                  type="button"
                  data-toggle="collapse"
                  data-target="#collapseOne"
                  aria-expanded="true"
                  aria-controls="collapseOne"
                >
                  Terms of Use
                </button>
              </h2>
            </div>

            <div
              id="collapseOne"
              className="collapse"
              aria-labelledby="headingOne"
              data-parent="#accordionExample"
            >
              <div className="card-body">
                These Terms and Conditions constitute a legally binding
                agreement made between you, whether personally or on behalf of
                an entity (“you”) and The Social Spoon (“we,” “us” or “our”),
                concerning your access to and use of the https://socialspoon.org
                website as well as any other media form, media channel, mobile
                website or mobile application related, linked, or otherwise
                connected thereto (collectively, the “Site”). You agree that by
                accessing the Site, you have read, understood, and agree to be
                bound by all of these Terms and Conditions. If you do not agree
                with all of these Terms and Conditions, then you are expressly
                prohibited from using the Site and you must discontinue use
                immediately. Supplemental terms and conditions or documents that
                may be posted on the Site from time to time are hereby expressly
                incorporated herein by reference. We reserve the right, in our
                sole discretion, to make changes or modifications to these Terms
                and Conditions at any time and for any reason. It is your
                responsibility to periodically review these Terms and Conditions
                to stay informed of updates. You will be subject to, and will be
                deemed to have been made aware of and to have accepted, the
                changes in any revised Terms and Conditions by your continued
                use of the Site after the date such revised Terms and Conditions
                are posted. The information provided on the Site is not intended
                for distribution to or use by any person or entity in any
                jurisdiction or country where such distribution or use would be
                contrary to law or regulation or which would subject us to any
                registration requirement within such jurisdiction or country.
                Accordingly, those persons who choose to access the Site from
                other locations do so on their own initiative and are solely
                responsible for compliance with local laws, if and to the extent
                local laws are applicable. The Site is intended for users who
                are at least 18 years old. Persons under the age of 18 are not
                permitted to register for the Site. INTELLECTUAL PROPERTY RIGHTS
                Unless otherwise indicated, the Site is our proprietary property
                and all source code, databases, functionality, software, website
                designs, audio, video, text, photographs, and graphics on the
                Site (collectively, the “Content”) and the trademarks, service
                marks, and logos contained therein (the “Marks”) are owned or
                controlled by us or licensed to us, and are protected by
                copyright and trademark laws and various other intellectual
                property rights and unfair competition laws of the United
                States, foreign jurisdictions, and international conventions.
                The Content and the Marks are provided on the Site “AS IS” for
                your information and personal use only. Except as expressly
                provided in these Terms and Conditions, no part of the Site and
                no Content or Marks may be copied, reproduced, aggregated,
                republished, uploaded, posted, publicly displayed, encoded,
                translated, transmitted, distributed, sold, licensed, or
                otherwise exploited for any commercial purpose whatsoever,
                without our express prior written permission. Provided that you
                are eligible to use the Site, you are granted a limited license
                to access and use the Site and to download or print a copy of
                any portion of the Content to which you have properly gained
                access solely for your personal, non-commercial use. We reserve
                all rights not expressly granted to you in and to the Site, the
                Content and the Marks. USER REPRESENTATIONS By using the Site,
                you represent and warrant that: (1) all registration information
                you submit will be true, accurate, current, and complete; (2)
                you have the legal capacity and you agree to comply with these
                Terms and Conditions; (3) you are not under the age of 18; (4)
                you will not access the Site through automated or non-human
                means, whether through a bot, script, or otherwise; (5) you will
                not use the Site for any illegal or unauthorized purpose; (6)
                your use of the Site will not violate any applicable law or
                regulation. If you provide any information that is untrue,
                inaccurate, not current, or incomplete, we have the right to
                suspend or terminate your account and refuse any and all current
                or future use of the Site (or any portion thereof). USER
                REGISTRATION You may be required to register with the Site. You
                agree to keep your password confidential and will be responsible
                for all use of your account and password. We reserve the right
                to remove, reclaim, or change a username you select if we
                determine, in our sole discretion, that such username is
                inappropriate, obscene, or otherwise objectionable. PROHIBITED
                ACTIVITIES You may not access or use the Site for any purpose
                other than that for which we make the Site available. The Site
                may not be used in connection with any commercial endeavors
                except those that are specifically endorsed or approved by us.
                As a user of the Site, you agree not to: 1. systematically
                retrieve data or other content from the Site to create or
                compile, directly or indirectly, a collection, compilation,
                database, or directory without written permission from us. 2.
                make any unauthorized use of the Site, including collecting
                usernames and/or email addresses of users by electronic or other
                means for the purpose of sending unsolicited email, or creating
                user accounts by automated means or under false pretenses. 3.
                use a buying agent or purchasing agent to make purchases on the
                Site. 4. use the Site to advertise or offer to sell goods and
                services. 5. circumvent, disable, or otherwise interfere with
                security-related features of the Site, including features that
                prevent or restrict the use or copying of any Content or enforce
                limitations on the use of the Site and/or the Content contained
                therein. 6. engage in unauthorized framing of or linking to the
                Site. 7. trick, defraud, or mislead us and other users,
                especially in any attempt to learn sensitive account information
                such as user passwords; 8. make improper use of our support
                services or submit false reports of abuse or misconduct. 9.
                engage in any automated use of the system, such as using scripts
                to send comments or messages, or using any data mining, robots,
                or similar data gathering and extraction tools. 10. interfere
                with, disrupt, or create an undue burden on the Site or the
                networks or services connected to the Site. 11. attempt to
                impersonate another user or person or use the username of
                another user. 12. sell or otherwise transfer your profile. 13.
                use any information obtained from the Site in order to harass,
                abuse, or harm another person. 14. use the Site as part of any
                effort to compete with us or otherwise use the Site and/or the
                Content for any revenue-generating endeavor or commercial
                enterprise. 15. decipher, decompile, disassemble, or reverse
                engineer any of the software comprising or in any way making up
                a part of the Site. 16. attempt to bypass any measures of the
                Site designed to prevent or restrict access to the Site, or any
                portion of the Site. 17. harass, annoy, intimidate, or threaten
                any of our employees or agents engaged in providing any portion
                of the Site to you. 18. delete the copyright or other
                proprietary rights notice from any Content. 19. copy or adapt
                the Site’s software, including but not limited to Flash, PHP,
                HTML, JavaScript, or other code. 20. upload or transmit (or
                attempt to upload or to transmit) viruses, Trojan horses, or
                other material, including excessive use of capital letters and
                spamming (continuous posting of repetitive text), that
                interferes with any party’s uninterrupted use and enjoyment of
                the Site or modifies, impairs, disrupts, alters, or interferes
                with the use, features, functions, operation, or maintenance of
                the Site. 21. upload or transmit (or attempt to upload or to
                transmit) any material that acts as a passive or active
                information collection or transmission mechanism, including
                without limitation, clear graphics interchange formats (“gifs”),
                1×1 pixels, web bugs, cookies, or other similar devices
                (sometimes referred to as “spyware” or “passive collection
                mechanisms” or “pcms”). 22. except as may be the result of
                standard search engine or Internet browser usage, use, launch,
                develop, or distribute any automated system, including without
                limitation, any spider, robot, cheat utility, scraper, or
                offline reader that accesses the Site, or using or launching any
                unauthorized script or other software. 23. disparage, tarnish,
                or otherwise harm, in our opinion, us and/or the Site. 24. use
                the Site in a manner inconsistent with any applicable laws or
                regulations. USER GENERATED CONTRIBUTIONS The Site may invite
                you to interact, contribute to, or participate in blogs, message
                boards, online forums, and other functionality, and may provide
                you with the opportunity to create, submit, post, display,
                transmit, perform, publish, distribute, or broadcast content and
                materials to us or on the Site, including but not limited to
                text, writings, video, audio, photographs, graphics, comments,
                suggestions, or personal information or other material
                (collectively, "Contributions"). Contributions may be viewable
                by other users of the Site and through third-party websites. As
                such, any Contributions you transmit may be treated as
                non-confidential and non-proprietary. When you create or make
                available any Contributions, you thereby represent and warrant
                that: 1. the creation, distribution, transmission, public
                display, or performance, and the accessing, downloading, or
                copying of your Contributions do not and will not infringe the
                proprietary rights, including but not limited to the copyright,
                patent, trademark, trade secret, or moral rights of any third
                party. 2. you are the creator and owner of or have the necessary
                licenses, rights, consents, releases, and permissions to use and
                to authorize us, the Site, and other users of the Site to use
                your Contributions in any manner contemplated by the Site and
                these Terms and Conditions. 3. you have the written consent,
                release, and/or permission of each and every identifiable
                individual person in your Contributions to use the name or
                likeness of each and every such identifiable individual person
                to enable inclusion and use of your Contributions in any manner
                contemplated by the Site and these Terms and Conditions. 4. your
                Contributions are not false, inaccurate, or misleading. 5. your
                Contributions are not unsolicited or unauthorized advertising,
                promotional materials, pyramid schemes, chain letters, spam,
                mass mailings, or other forms of solicitation. 6. your
                Contributions are not obscene, lewd, lascivious, filthy,
                violent, harassing, libelous, slanderous, or otherwise
                objectionable (as determined by us). 7. your Contributions do
                not ridicule, mock, disparage, intimidate, or abuse anyone. 8.
                your Contributions do not advocate the violent overthrow of any
                government or incite, encourage, or threaten physical harm
                against another. 9. your Contributions do not violate any
                applicable law, regulation, or rule. 10. your Contributions do
                not violate the privacy or publicity rights of any third party.
                11. your Contributions do not contain any material that solicits
                personal information from anyone under the age of 18 or exploits
                people under the age of 18 in a sexual or violent manner. 12.
                your Contributions do not violate any federal or state law
                concerning child pornography, or otherwise intended to protect
                the health or well-being of minors; 13. your Contributions do
                not include any offensive comments that are connected to race,
                national origin, gender, sexual preference, or physical
                handicap. 14. your Contributions do not otherwise violate, or
                link to material that violates, any provision of these Terms and
                Conditions, or any applicable law or regulation. Any use of the
                Site in violation of the foregoing violates these Terms and
                Conditions and may result in, among other things, termination or
                suspension of your rights to use the Site. CONTRIBUTION LICENSE
                By posting your Contributions to any part of the Site or making
                Contributions accessible to the Site by linking your account
                from the Site to any of your social networking accounts, you
                automatically grant, and you represent and warrant that you have
                the right to grant, to us an unrestricted, unlimited,
                irrevocable, perpetual, non-exclusive, transferable,
                royalty-free, fully-paid, worldwide right, and license to host,
                use, copy, reproduce, disclose, sell, resell, publish,
                broadcast, retitle, archive, store, cache, publicly perform,
                publicly display, reformat, translate, transmit, excerpt (in
                whole or in part), and distribute such Contributions (including,
                without limitation, your image and voice) for any purpose,
                commercial, advertising, or otherwise, and to prepare derivative
                works of, or incorporate into other works, such Contributions,
                and grant and authorize sublicenses of the foregoing. The use
                and distribution may occur in any media formats and through any
                media channels. This license will apply to any form, media, or
                technology now known or hereafter developed, and includes our
                use of your name, company name, and franchise name, as
                applicable, and any of the trademarks, service marks, trade
                names, logos, and personal and commercial images you provide.
                You waive all moral rights in your Contributions, and you
                warrant that moral rights have not otherwise been asserted in
                your Contributions. We do not assert any ownership over your
                Contributions. You retain full ownership of all of your
                Contributions and any intellectual property rights or other
                proprietary rights associated with your Contributions. We are
                not liable for any statements or representations in your
                Contributions provided by you in any area on the Site. You are
                solely responsible for your Contributions to the Site and you
                expressly agree to exonerate us from any and all responsibility
                and to refrain from any legal action against us regarding your
                Contributions. We have the right, in our sole and absolute
                discretion, (1) to edit, redact, or otherwise change any
                Contributions; (2) to re-categorize any Contributions to place
                them in more appropriate locations on the Site; and (3) to
                pre-screen or delete any Contributions at any time and for any
                reason, without notice. We have no obligation to monitor your
                Contributions. GUIDELINES FOR REVIEWS We may provide you areas
                on the Site to leave reviews or ratings. When posting a review,
                you must comply with the following criteria: (1) you should have
                firsthand experience with the person/entity being reviewed; (2)
                your reviews should not contain references to illegal activity;
                (3) you should not be affiliated with competitors if posting
                negative reviews; (4) you may not post any false or misleading
                statements; (5) you may not organize a campaign encouraging
                others to post reviews, whether positive or negative. We may
                accept, reject, or remove reviews in our sole discretion. We
                have absolutely no obligation to screen reviews or to delete
                reviews, even if anyone considers reviews objectionable or
                inaccurate. Reviews and recipes are not endorsed by us, and do
                not necessarily represent our opinions or the views of any of
                our affiliates or partners. We do not assume liability for any
                review or for any claims, liabilities, or losses resulting from
                any review. By posting a review, you hereby grant to us a
                perpetual, non-exclusive, worldwide, royalty-free, fully-paid,
                assignable, and sublicensable right and license to reproduce,
                modify, translate, transmit by any means, display, perform,
                and/or distribute all content relating to reviews. SUBMISSIONS
                You acknowledge and agree that any questions, comments,
                suggestions, ideas, feedback, or other information regarding the
                Site ("Submissions") provided by you to us are non-confidential
                and shall become our sole property. We shall own exclusive
                rights, including all intellectual property rights, and shall be
                entitled to the unrestricted use and dissemination of these
                Submissions for any lawful purpose, commercial or otherwise,
                without acknowledgment or compensation to you. You hereby waive
                all moral rights to any such Submissions, and you hereby warrant
                that any such Submissions are original with you or that you have
                the right to submit such Submissions. You agree there shall be
                no recourse against us for any alleged or actual infringement or
                misappropriation of any proprietary right in your Submissions.
                ADVERTISERS We allow advertisers to display their advertisements
                and other information in certain areas of the Site, such as
                sidebar advertisements or banner advertisements. If you are an
                advertiser, you shall take full responsibility for any
                advertisements you place on the Site and any services provided
                on the Site or products sold through those advertisements.
                Further, as an advertiser, you warrant and represent that you
                possess all rights and authority to place advertisements on the
                Site, including, but not limited to, intellectual property
                rights, publicity rights, and contractual rights. As an
                advertiser, you agree that such advertisements are subject to
                our Digital Millennium Copyright Act (“DMCA”) Notice and Policy
                provisions as described below, and you understand and agree
                there will be no refund or other compensation for DMCA
                takedown-related issues. We simply provide the space to place
                such advertisements, and we have no other relationship with
                advertisers. SITE MANAGEMENT We reserve the right, but not the
                obligation, to: (1) monitor the Site for violations of these
                Terms and Conditions; (2) take appropriate action against anyone
                who, in our sole discretion, violates the law or these Terms and
                Conditions, including without limitation, reporting such user to
                law enforcement authorities; (3) in our sole discretion and
                without limitation, refuse, restrict access to, limit the
                availability of, or disable (to the extent technologically
                feasible) any of your Contributions or any portion thereof; (4)
                in our sole discretion and without limitation, notice, or
                liability, to remove from the Site or otherwise disable all
                files and content that are excessive in size or are in any way
                burdensome to our systems; (5) otherwise manage the Site in a
                manner designed to protect our rights and property and to
                facilitate the proper functioning of the Site. PRIVACY POLICY We
                care about data privacy and security. Please review our Privacy
                Policy. By using the Site, you agree to be bound by our Privacy
                Policy, which is incorporated into these Terms and Conditions.
                Please be advised the Site is hosted in the United States. If
                you access the Site from the European Union, Asia, or any other
                region of the world with laws or other requirements governing
                personal data collection, use, or disclosure that differ from
                applicable laws in the United States, then through your
                continued use of the Site, you are transferring your data to the
                United States, and you expressly consent to have your data
                transferred to and processed in the United States. Further, we
                do not knowingly accept, request, or solicit information from
                children or knowingly market to children. Therefore, in
                accordance with the U.S. Children’s Online Privacy Protection
                Act, if we receive actual knowledge that anyone under the age of
                13 has provided personal information to us without the requisite
                and verifiable parental consent, we will delete that information
                from the Site as quickly as is reasonably practical. DIGITAL
                MILLENNIUM COPYRIGHT ACT (DMCA) NOTICE AND POLICY Notifications
                We respect the intellectual property rights of others. If you
                believe that any material available on or through the Site
                infringes upon any copyright you own or control, please
                immediately notify our Designated Copyright Agent using the
                contact information provided below (a “Notification”). A copy of
                your Notification will be sent to the person who posted or
                stored the material addressed in the Notification. Please be
                advised that pursuant to federal law you may be held liable for
                damages if you make material misrepresentations in a
                Notification. Thus, if you are not sure that material located on
                or linked to by the Site infringes your copyright, you should
                consider first contacting an attorney. All Notifications should
                meet the requirements of DMCA 17 U.S.C. § 512(c)(3) and include
                the following information: (1) A physical or electronic
                signature of a person authorized to act on behalf of the owner
                of an exclusive right that is allegedly infringed; (2)
                identification of the copyrighted work claimed to have been
                infringed, or, if multiple copyrighted works on the Site are
                covered by the Notification, a representative list of such works
                on the Site; (3) identification of the material that is claimed
                to be infringing or to be the subject of infringing activity and
                that is to be removed or access to which is to be disabled, and
                information reasonably sufficient to permit us to locate the
                material; (4) information reasonably sufficient to permit us to
                contact the complaining party, such as an address, telephone
                number, and, if available, an email address at which the
                complaining party may be contacted; (5) a statement that the
                complaining party has a good faith belief that use of the
                material in the manner complained of is not authorized by the
                copyright owner, its agent, or the law; (6) a statement that the
                information in the notification is accurate, and under penalty
                of perjury, that the complaining party is authorized to act on
                behalf of the owner of an exclusive right that is allegedly
                infringed upon. Designated Copyright Agent
                copyright@socialspoon.org COPYRIGHT INFRINGEMENTS We respect the
                intellectual property rights of others. If you believe that any
                material available on or through the Site infringes upon any
                copyright you own or control, please immediately notify us using
                the contact information provided below (a “Notification”). A
                copy of your Notification will be sent to the person who posted
                or stored the material addressed in the Notification. Please be
                advised that pursuant to federal law you may be held liable for
                damages if you make material misrepresentations in a
                Notification. Thus, if you are not sure that material located on
                or linked to by the Site infringes your copyright, you should
                consider first contacting an attorney.] TERM AND TERMINATION
                These Terms and Conditions shall remain in full force and effect
                while you use the Site. WITHOUT LIMITING ANY OTHER PROVISION OF
                THESE TERMS AND CONDITIONS, WE RESERVE THE RIGHT TO, IN OUR SOLE
                DISCRETION AND WITHOUT NOTICE OR LIABILITY, DENY ACCESS TO AND
                USE OF THE SITE (INCLUDING BLOCKING CERTAIN IP ADDRESSES), TO
                ANY PERSON FOR ANY REASON OR FOR NO REASON, INCLUDING WITHOUT
                LIMITATION FOR BREACH OF ANY REPRESENTATION, WARRANTY, OR
                COVENANT CONTAINED IN THESE TERMS AND CONDITIONS OR OF ANY
                APPLICABLE LAW OR REGULATION. WE MAY TERMINATE YOUR USE OR
                PARTICIPATION IN THE SITE OR DELETE ANY CONTENT OR INFORMATION
                THAT YOU POSTED AT ANY TIME, WITHOUT WARNING, IN OUR SOLE
                DISCRETION. If we terminate or suspend your account for any
                reason, you are prohibited from registering and creating a new
                account under your name, a fake or borrowed name, or the name of
                any third party, even if you may be acting on behalf of the
                third party. In addition to terminating or suspending your
                account, we reserve the right to take appropriate legal action,
                including without limitation pursuing civil, criminal, and
                injunctive redress. MODIFICATIONS AND INTERRUPTIONS We reserve
                the right to change, modify, or remove the contents of the Site
                at any time or for any reason at our sole discretion without
                notice. However, we have no obligation to update any information
                on our Site. We also reserve the right to modify or discontinue
                all or part of the Site without notice at any time. We will not
                be liable to you or any third party for any modification, price
                change, suspension, or discontinuance of the Site. We cannot
                guarantee the Site will be available at all times. We may
                experience hardware, software, or other problems or need to
                perform maintenance related to the Site, resulting in
                interruptions, delays, or errors. We reserve the right to
                change, revise, update, suspend, discontinue, or otherwise
                modify the Site at any time or for any reason without notice to
                you. You agree that we have no liability whatsoever for any
                loss, damage, or inconvenience caused by your inability to
                access or use the Site during any downtime or discontinuance of
                the Site. Nothing in these Terms and Conditions will be
                construed to obligate us to maintain and support the Site or to
                supply any corrections, updates, or releases in connection
                therewith. GOVERNING LAW These Terms and Conditions and your use
                of the Site are governed by and construed in accordance with the
                laws of the State of Illinois applicable to agreements made and
                to be entirely performed within the State of Illinois, without
                regard to its conflict of law principles. DISPUTE RESOLUTION Any
                legal action of whatever nature brought by either you or us
                (collectively, the “Parties” and individually, a “Party”) shall
                be commenced or prosecuted in the state and federal courts
                located in Cook County, Illinois, and the Parties hereby consent
                to, and waive all defenses of lack of personal jurisdiction and
                forum non conveniens with respect to venue and jurisdiction in
                such state and federal courts. Binding Arbitration If the
                Parties are unable to resolve a Dispute through informal
                negotiations, the Dispute (except those Disputes expressly
                excluded below) will be finally and exclusively resolved by
                binding arbitration. YOU UNDERSTAND THAT WITHOUT THIS PROVISION,
                YOU WOULD HAVE THE RIGHT TO SUE IN COURT AND HAVE A JURY TRIAL.
                The arbitration shall be commenced and conducted under the
                Commercial Arbitration Rules of the American Arbitration
                Association ("AAA") and, where appropriate, the AAA’s
                Supplementary Procedures for Consumer Related Disputes ("AAA
                Consumer Rules"), both of which are available at the AAA website
                www.adr.org. Your arbitration fees and your share of arbitrator
                compensation shall be governed by the AAA Consumer Rules and,
                where appropriate, limited by the AAA Consumer Rules. The
                arbitration may be conducted in person, through the submission
                of documents, by phone, or online. The arbitrator will make a
                decision in writing, but need not provide a statement of reasons
                unless requested by either Party. The arbitrator must follow
                applicable law, and any award may be challenged if the
                arbitrator fails to do so. Except where otherwise required by
                the applicable AAA rules or applicable law, the arbitration will
                take place in Cook County, Illinois. Except as otherwise
                provided herein, the Parties may litigate in court to compel
                arbitration, stay proceedings pending arbitration, or to
                confirm, modify, vacate, or enter judgment on the award entered
                by the arbitrator. If for any reason, a Dispute proceeds in
                court rather than arbitration, the Dispute shall be commenced or
                prosecuted in the state and federal courts located in Cook
                County, Illinois, and the Parties hereby consent to, and waive
                all defenses of lack of personal jurisdiction, and forum non
                conveniens with respect to venue and jurisdiction in such state
                and federal courts. Application of the United Nations Convention
                on Contracts for the International Sale of Goods and the the
                Uniform Computer Information Transaction Act (UCITA) are
                excluded from these Terms and Conditions. In no event shall any
                Dispute brought by either Party related in any way to the Site
                be commenced more than 1 year after the cause of action arose.
                If this provision is found to be illegal or unenforceable, then
                neither Party will elect to arbitrate any Dispute falling within
                that portion of this provision found to be illegal or
                unenforceable and such Dispute shall be decided by a court of
                competent jurisdiction within the courts listed for jurisdiction
                above, and the Parties agree to submit to the personal
                jurisdiction of that court. CORRECTIONS There may be information
                on the Site that contains typographical errors, inaccuracies, or
                omissions that may relate to the Site, including descriptions,
                pricing, availability, and various other information. We reserve
                the right to correct any errors, inaccuracies, or omissions and
                to change or update the information on the Site at any time,
                without prior notice. DISCLAIMER THE SITE IS PROVIDED ON AN
                AS-IS AND AS-AVAILABLE BASIS. YOU AGREE THAT YOUR USE OF THE
                SITE AND OUR SERVICES WILL BE AT YOUR SOLE RISK. TO THE FULLEST
                EXTENT PERMITTED BY LAW, WE DISCLAIM ALL WARRANTIES, EXPRESS OR
                IMPLIED, IN CONNECTION WITH THE SITE AND YOUR USE THEREOF,
                INCLUDING, WITHOUT LIMITATION, THE IMPLIED WARRANTIES OF
                MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND
                NON-INFRINGEMENT. WE MAKE NO WARRANTIES OR REPRESENTATIONS ABOUT
                THE ACCURACY OR COMPLETENESS OF THE SITE’S CONTENT OR THE
                CONTENT OF ANY WEBSITES LINKED TO THE SITE AND WE WILL ASSUME NO
                LIABILITY OR RESPONSIBILITY FOR ANY (1) ERRORS, MISTAKES, OR
                INACCURACIES OF CONTENT AND MATERIALS, (2) PERSONAL INJURY OR
                PROPERTY DAMAGE, OF ANY NATURE WHATSOEVER, RESULTING FROM YOUR
                ACCESS TO AND USE OF THE SITE, (3) ANY UNAUTHORIZED ACCESS TO OR
                USE OF OUR SECURE SERVERS AND/OR ANY AND ALL PERSONAL
                INFORMATION AND/OR FINANCIAL INFORMATION STORED THEREIN, (4) ANY
                INTERRUPTION OR CESSATION OF TRANSMISSION TO OR FROM THE SITE,
                (5) ANY BUGS, VIRUSES, TROJAN HORSES, OR THE LIKE WHICH MAY BE
                TRANSMITTED TO OR THROUGH THE SITE BY ANY THIRD PARTY, AND/OR
                (6) ANY ERRORS OR OMISSIONS IN ANY CONTENT AND MATERIALS OR FOR
                ANY LOSS OR DAMAGE OF ANY KIND INCURRED AS A RESULT OF THE USE
                OF ANY CONTENT POSTED, TRANSMITTED, OR OTHERWISE MADE AVAILABLE
                VIA THE SITE. WE DO NOT WARRANT, ENDORSE, GUARANTEE, OR ASSUME
                RESPONSIBILITY FOR ANY PRODUCT OR SERVICE ADVERTISED OR OFFERED
                BY A THIRD PARTY THROUGH THE SITE, ANY HYPERLINKED WEBSITE, OR
                ANY WEBSITE OR MOBILE APPLICATION FEATURED IN ANY BANNER OR
                OTHER ADVERTISING, AND WE WILL NOT BE A PARTY TO OR IN ANY WAY
                BE RESPONSIBLE FOR MONITORING ANY TRANSACTION BETWEEN YOU AND
                ANY THIRD-PARTY PROVIDERS OF PRODUCTS OR SERVICES. AS WITH THE
                PURCHASE OF A PRODUCT OR SERVICE THROUGH ANY MEDIUM OR IN ANY
                ENVIRONMENT, YOU SHOULD USE YOUR BEST JUDGMENT AND EXERCISE
                CAUTION WHERE APPROPRIATE. LIMITATIONS OF LIABILITY IN NO EVENT
                WILL WE OR OUR DIRECTORS, EMPLOYEES, OR AGENTS BE LIABLE TO YOU
                OR ANY THIRD PARTY FOR ANY DIRECT, INDIRECT, CONSEQUENTIAL,
                EXEMPLARY, INCIDENTAL, SPECIAL, OR PUNITIVE DAMAGES, INCLUDING
                LOST PROFIT, LOST REVENUE, LOSS OF DATA, OR OTHER DAMAGES
                ARISING FROM YOUR USE OF THE SITE, EVEN IF WE HAVE BEEN ADVISED
                OF THE POSSIBILITY OF SUCH DAMAGES. INDEMNIFICATION You agree to
                defend, indemnify, and hold us harmless, including our
                subsidiaries, affiliates, and all of our respective officers,
                agents, partners, and employees, from and against any loss,
                damage, liability, claim, or demand, including reasonable
                attorneys’ fees and expenses, made by any third party due to or
                arising out of: (1) your use of Recipes; (2) use of the Site;
                (3) breach of these Terms and Conditions; (4) any breach of your
                representations and warranties set forth in these Terms and
                Conditions; (5) your violation of the rights of a third party,
                including but not limited to intellectual property rights; or
                (6) any overt harmful act toward any other user of the Site with
                whom you connected via the Site. Notwithstanding the foregoing,
                we reserve the right, at your expense, to assume the exclusive
                defense and control of any matter for which you are required to
                indemnify us, and you agree to cooperate, at your expense, with
                our defense of such claims. We will use reasonable efforts to
                notify you of any such claim, action, or proceeding which is
                subject to this indemnification upon becoming aware of it. USER
                DATA We will maintain certain data that you transmit to the Site
                for the purpose of managing the Site, as well as data relating
                to your use of the Site. Although we perform regular routine
                backups of data, you are solely responsible for all data that
                you transmit or that relates to any activity you have undertaken
                using the Site. You agree that we shall have no liability to you
                for any loss or corruption of any such data, and you hereby
                waive any right of action against us arising from any such loss
                or corruption of such data. ELECTRONIC COMMUNICATIONS,
                TRANSACTIONS, AND SIGNATURES Visiting the Site, sending us
                emails, and completing online forms constitute electronic
                communications. You consent to receive electronic
                communications, and you agree that all agreements, notices,
                disclosures, and other communications we provide to you
                electronically, via email and on the Site, satisfy any legal
                requirement that such communication be in writing. YOU HEREBY
                AGREE TO THE USE OF ELECTRONIC SIGNATURES, CONTRACTS, ORDERS,
                AND OTHER RECORDS, AND TO ELECTRONIC DELIVERY OF NOTICES,
                POLICIES, AND RECORDS OF TRANSACTIONS INITIATED OR COMPLETED BY
                US OR VIA THE SITE. You hereby waive any rights or requirements
                under any statutes, regulations, rules, ordinances, or other
                laws in any jurisdiction which require an original signature or
                delivery or retention of non-electronic records, or to payments
                or the granting of credits by any means other than electronic
                means. CALIFORNIA USERS AND RESIDENTS If any complaint with us
                is not satisfactorily resolved, you can contact the Complaint
                Assistance Unit of the Division of Consumer Services of the
                California Department of Consumer Affairs in writing at 1625
                North Market Blvd., Suite N 112, Sacramento, California 95834 or
                by telephone at (800) 952-5210 or (916) 445-1254. MISCELLANEOUS
                These Terms and Conditions and any policies or operating rules
                posted by us on the Site constitute the entire agreement and
                understanding between you and us. Our failure to exercise or
                enforce any right or provision of these Terms and Conditions
                shall not operate as a waiver of such right or provision. These
                Terms and Conditions operate to the fullest extent permissible
                by law. We may assign any or all of our rights and obligations
                to others at any time. We shall not be responsible or liable for
                any loss, damage, delay, or failure to act caused by any cause
                beyond our reasonable control. If any provision or part of a
                provision of these Terms and Conditions is determined to be
                unlawful, void, or unenforceable, that provision or part of the
                provision is deemed severable from these Terms and Conditions
                and does not affect the validity and enforceability of any
                remaining provisions. There is no joint venture, partnership,
                employment or agency relationship created between you and us as
                a result of these Terms and Conditions or use of the Site. You
                agree that these Terms and Conditions will not be construed
                against us by virtue of having drafted them. You hereby waive
                any and all defenses you may have based on the electronic form
                of these Terms and Conditions and the lack of signing by the
                parties hereto to execute these Terms and Conditions. CONTACT US
                In order to resolve a complaint regarding the Site or to receive
                further information regarding use of the Site, please contact us
                at: tos@socialspoon.org
              </div>
            </div>
          </div>
          <div className="card">
            <div className="card-header" id="headingTwo">
              <h2 className="mb-0">
                <button
                  className="btn btn-link collapsed"
                  type="button"
                  data-toggle="collapse"
                  data-target="#collapseTwo"
                  aria-expanded="false"
                  aria-controls="collapseTwo"
                >
                  Privacy Policy
                </button>
              </h2>
            </div>
            <div
              id="collapseTwo"
              className="collapse"
              aria-labelledby="headingTwo"
              data-parent="#accordionExample"
            >
              <div className="card-body">
                Your privacy is important to us. It is our policy to respect
                your privacy and comply with any applicable law and regulation
                regarding any personal information we may collect about you,
                including across our website, https://socialspoon.org, and other
                sites we own and operate. This policy is effective as of 01
                January 2021. Information We Collect Information we collect
                includes both information you knowingly and actively provide us
                when using or participating in any of our services and
                promotions, and any information automatically sent by your
                devices in the course of accessing our products and services.
                Log Data When you visit our website, our servers may
                automatically log the standard data provided by your web
                browser. It may include your device’s Internet Protocol (IP)
                address, your browser type and version, the pages you visit, the
                time and date of your visit, the time spent on each page, other
                details about your visit, and technical details that occur in
                conjunction with any errors you may encounter. Please be aware
                that while this information may not be personally identifying by
                itself, it may be possible to combine it with other data to
                personally identify individual persons. Personal Information We
                may ask for personal information which may include one or more
                of the following: Name Email Legitimate Reasons for Processing
                Your Personal Information We only collect and use your personal
                information when we have a legitimate reason for doing so. In
                which instance, we only collect personal information that is
                reasonably necessary to provide our services to you. Collection
                and Use of Information We may collect personal information from
                you when you do any of the following on our website: Use a
                mobile device or web browser to access our content Contact us
                via email, social media, or on any similar technologies When you
                mention us on social media We may collect, hold, use, and
                disclose information for the following purposes, and personal
                information will not be further processed in a manner that is
                incompatible with these purposes: We may collect, hold, use, and
                disclose information for the following purposes, and personal
                information will not be further processed in a manner that is
                incompatible with these purposes: to contact and communicate
                with you to enable you to access and use our website, associated
                applications, and associated social media platforms for internal
                record keeping and administrative purposes Please be aware that
                we may combine information we collect about you with general
                information or research data we receive from other trusted
                sources. Security of Your Personal Information When we collect
                and process personal information, and while we retain this
                information, we will protect it within commercially acceptable
                means to prevent loss and theft, as well as unauthorised access,
                disclosure, copying, use, or modification. Although we will do
                our best to protect the personal information you provide to us,
                we advise that no method of electronic transmission or storage
                is 100% secure, and no one can guarantee absolute data security.
                We will comply with laws applicable to us in respect of any data
                breach. You are responsible for selecting any password and its
                overall security strength, ensuring the security of your own
                information within the bounds of our services. How Long We Keep
                Your Personal Information We keep your personal information only
                for as long as we need to. This time period may depend on what
                we are using your information for, in accordance with this
                privacy policy. If your personal information is no longer
                required, we will delete it or make it anonymous by removing all
                details that identify you. However, if necessary, we may retain
                your personal information for our compliance with a legal,
                accounting, or reporting obligation or for archiving purposes in
                the public interest, scientific, or historical research purposes
                or statistical purposes. Children’s Privacy We do not aim any of
                our products or services directly at children under the age of
                13, and we do not knowingly collect personal information about
                children under 13. Disclosure of Personal Information to Third
                Parties We may disclose personal information to: a parent,
                subsidiary, or affiliate of our company third party service
                providers for the purpose of enabling them to provide their
                services, for example, IT service providers, data storage,
                hosting and server providers, advertisers, or analytics
                platforms our employees, contractors, and/or related entities
                our existing or potential agents or business partners sponsors
                or promoters of any competition, sweepstakes, or promotion we
                run courts, tribunals, regulatory authorities, and law
                enforcement officers, as required by law, in connection with any
                actual or prospective legal proceedings, or in order to
                establish, exercise, or defend our legal rights third parties,
                including agents or sub-contractors, who assist us in providing
                information, products, services, or direct marketing to you
                third parties to collect and process data International
                Transfers of Personal Information The personal information we
                collect is stored and/or processed where we or our partners,
                affiliates, and third-party providers maintain facilities.
                Please be aware that the locations to which we store, process,
                or transfer your personal information may not have the same data
                protection laws as the country in which you initially provided
                the information. If we transfer your personal information to
                third parties in other countries: (i) we will perform those
                transfers in accordance with the requirements of applicable law;
                and (ii) we will protect the transferred personal information in
                accordance with this privacy policy. Your Rights and Controlling
                Your Personal Information You always retain the right to
                withhold personal information from us, with the understanding
                that your experience of our website may be affected. We will not
                discriminate against you for exercising any of your rights over
                your personal information. If you do provide us with personal
                information you understand that we will collect, hold, use and
                disclose it in accordance with this privacy policy. You retain
                the right to request details of any personal information we hold
                about you. If we receive personal information about you from a
                third party, we will protect it as set out in this privacy
                policy. If you are a third party providing personal information
                about somebody else, you represent and warrant that you have
                such person’s consent to provide the personal information to us.
                If you have previously agreed to us using your personal
                information for direct marketing purposes, you may change your
                mind at any time. We will provide you with the ability to
                unsubscribe from our email-database or opt out of
                communications. Please be aware we may need to request specific
                information from you to help us confirm your identity. If you
                believe that any information we hold about you is inaccurate,
                out of date, incomplete, irrelevant, or misleading, please
                contact us using the details provided in this privacy policy. We
                will take reasonable steps to correct any information found to
                be inaccurate, incomplete, misleading, or out of date. If you
                believe that we have breached a relevant data protection law and
                wish to make a complaint, please contact us using the details
                below and provide us with full details of the alleged breach. We
                will promptly investigate your complaint and respond to you, in
                writing, setting out the outcome of our investigation and the
                steps we will take to deal with your complaint. You also have
                the right to contact a regulatory body or data protection
                authority in relation to your complaint. Limits of Our Policy
                Our website may link to external sites that are not operated by
                us. Please be aware that we have no control over the content and
                policies of those sites, and cannot accept responsibility or
                liability for their respective privacy practices. Changes to
                This Policy At our discretion, we may change our privacy policy
                to reflect updates to our business processes, current acceptable
                practices, or legislative or regulatory changes. If we decide to
                change this privacy policy, we will post the changes here at the
                same link by which you are accessing this privacy policy. If
                required by law, we will get your permission or give you the
                opportunity to opt in to or opt out of, as applicable, any new
                uses of your personal information. Contact Us For any questions
                or concerns regarding your privacy, you may contact us using the
                following details: privacy@socialspoon.org
              </div>
            </div>
          </div>
          <div className="card">
            <div className="card-header" id="headingThree">
              <h2 className="mb-0">
                <button
                  className="btn btn-link collapsed"
                  type="button"
                  data-toggle="collapse"
                  data-target="#collapseThree"
                  aria-expanded="false"
                  aria-controls="collapseThree"
                >
                  Cookie Disclosure
                </button>
              </h2>
            </div>
            <div
              id="collapseThree"
              className="collapse"
              aria-labelledby="headingThree"
              data-parent="#accordionExample"
            >
              <div className="card-body">
                We use cookies and other tracking technologies to improve your
                browsing experience on our website, to analyze our website
                traffic, and to understand where our visitors are coming from.
              </div>
            </div>
          </div>
          <div className="card">
            <div className="card-header" id="headingThree">
              <h2 className="mb-0">
                <button
                  className="btn btn-link collapsed"
                  type="button"
                  data-toggle="collapse"
                  data-target="#collapseFour"
                  aria-expanded="false"
                  aria-controls="collapseFour"
                >
                  Amazon Services LLC Associates Program Disclosure
                </button>
              </h2>
            </div>
            <div
              id="collapseFour"
              className="collapse"
              aria-labelledby="headingFour"
              data-parent="#accordionExample"
            >
              <div className="card-body">
                The Social Spoon is a participant in the Amazon Services LLC
                Associates Program; We may get paid if you buy something or take
                an action after clicking our links.
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Legal.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(Legal);
