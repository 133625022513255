import {
  GET_CURRENT_PROFILE,
  PROFILE_LOADING,
  CLEAR_CURRENT_PROFILE,
  UPLOAD_PROFILE_IMAGE,
  SAVE_RECIPE,
  REMOVE_RECIPE,
} from "../actions/types";

const initialState = {
  me: null,
  loading: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case PROFILE_LOADING:
      return { ...state, loading: true };
    case GET_CURRENT_PROFILE:
      return { ...state, me: action.payload, loading: false };
    case CLEAR_CURRENT_PROFILE:
      return { ...state, me: null };
    case UPLOAD_PROFILE_IMAGE:
      return { ...state, me: action.payload };
    case SAVE_RECIPE:
      return { ...state, me: action.payload, loading: false };
    case REMOVE_RECIPE:
      return { ...state, me: action.payload, loading: false };
    default:
      return state;
  }
}
