import {
  GET_PROFILE,
  GET_PROFILE_RECIPES,
  PROFILE_LOADING,
} from "../actions/types";

const initialState = {
  profile: null,
  profilerecipes: null,
  loading: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case PROFILE_LOADING:
      return { ...state, loading: true };
    case GET_PROFILE:
      return { ...state, profile: action.payload, loading: false };
    case GET_PROFILE_RECIPES:
      return { ...state, profilerecipes: action.payload, loading: false };

    default:
      return state;
  }
}
