import {
  UPDATE_RECIPE_FORM,
  UPLOAD_RECIPE_IMAGE_FORM,
  GET_RECIPE_FORM,
  RECIPE_FORM_LOADING,
} from "../actions/types";

const initalState = {
  image: null,
  recipeForm: null,
  loading: false,
};

export default function (state = initalState, action) {
  switch (action.type) {
    case RECIPE_FORM_LOADING:
      return { ...state, loading: true };
    //todo fix
    case UPDATE_RECIPE_FORM:
      return { ...state, recipeForm: action.payload, loading: false };
    case UPLOAD_RECIPE_IMAGE_FORM:
      return { ...state, image: action.payload };
    case GET_RECIPE_FORM:
      return { ...state, recipeForm: action.payload, loading: false };
    default:
      return state;
  }
}
