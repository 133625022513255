import axios from "axios";

import {
  GET_INGREDIENT,
  GET_INGREDIENTS,
  GET_INGREDIENT_META,
  INGREDIENT_LOADING,
  GET_INGREDIENT_RECIPES,
  GET_ERRORS
} from "./types";

export const getIngredients = (history) => (dispatch) => {
  dispatch(setIngredientLoading());

  axios
    .get(`/api/ingredients`)
    .then((res) => dispatch({ type: GET_INGREDIENTS, payload: res.data }))
    .catch((err) => dispatch({ type: GET_ERRORS, payload: err.response.data }));
};

export const getIngredientMeta = (ingredientData, history) => (dispatch) => {
  dispatch(setIngredientLoading());

  axios
    .get(`/api/ingredients/meta/${ingredientData}`)
    .then((res) => dispatch({ type: GET_INGREDIENT_META, payload: res.data }))
    .catch((err) => dispatch({ type: GET_ERRORS, payload: err.response.data }));
};

export const getIngredientByID = (ingredientData, history) => (dispatch) => {
  dispatch(setIngredientLoading());
  axios
    .get(`/api/ingredients/${ingredientData}`)
    .then((res) => dispatch({ type: GET_INGREDIENT, payload: res.data }))
    .catch((err) => dispatch({ type: GET_ERRORS, payload: err.response.data }));
};

export const getRecipesByIngredient = (ingredientData, history) => (
  dispatch
) => {
  axios
    .get(`/api/ingredients/recipes/${ingredientData}`)
    .then((res) =>
      dispatch({ type: GET_INGREDIENT_RECIPES, payload: res.data })
    )
    .catch((err) => dispatch({ type: GET_ERRORS, payload: err.response.data }));
};

export const setIngredientLoading = () => {
  return {
    type: INGREDIENT_LOADING,
  };
};
