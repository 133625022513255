import React, { Component } from "react";

import { connect } from "react-redux";
import { registerUser } from "../../actions/authActions";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import TextFieldGroup from "../common/TextFieldGroup";
import CheckBox from "../common/CheckBoxGroup";

class Register extends Component {
  constructor() {
    super();
    this.state = {
      email: "",
      password: "",
      password2: "",
      betacode: "",
      errors: {},
      terms: false,
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onChange(e) {
    const value =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;

    if (e.target.type === "checkbox") {
      console.log(value);
    }
    console.log(e);
    this.setState({
      [e.target.name]: value,
    });
  }

  onSubmit(e) {
    e.preventDefault();

    const newUser = {
      email: this.state.email,
      password: this.state.password,
      password2: this.state.password2,
      betacode: this.state.betacode,
      terms: this.state.terms,
    };

    this.props.registerUser(newUser, this.props.history);
  }

  componentDidMount() {
    // if (this.props.auth.isAuthenticated) {
    //   this.props.history.push("/dashboard");
    //   window.scrollTo(0, 0);
    // }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.errors) {
      this.setState({ errors: nextProps.errors });
    }
  }

  render() {
    const { errors } = this.state;

    return (
      <div className="register">
        <div className="container">
          <div className="row">
            <div className="col-md-8 m-auto">
              <h1 className="display-4 text-center site-title">
                Welcome to The Social Spoon
              </h1>
              <p className="lead text-center">
                It's the greatest thing since sliced bread.
              </p>
              <form noValidate onSubmit={this.onSubmit}>
                <TextFieldGroup
                  type="email"
                  name="email"
                  placeholder="Email Address"
                  value={this.state.email}
                  onChange={this.onChange}
                  error={errors.email}
                  label="Email"
                />
                <TextFieldGroup
                  type="password"
                  name="password"
                  placeholder="Password"
                  value={this.state.password}
                  onChange={this.onChange}
                  error={errors.password}
                  label="Password"
                />

                <TextFieldGroup
                  type="password"
                  name="password2"
                  placeholder="Confirm Password"
                  value={this.state.password2}
                  onChange={this.onChange}
                  error={errors.password2}
                  label="Confirm Password"
                />

                <TextFieldGroup
                  name="betacode"
                  placeholder="Invite Code"
                  value={this.state.betacode}
                  onChange={this.onChange}
                  error={errors.betacode}
                  label="Invite Code"
                />
                <CheckBox
                  name="terms"
                  label="Agree to terms and conditions"
                  onChange={this.onChange}
                  error={errors.terms}
                  defaultChecked={this.state.terms}
                ></CheckBox>

                <input
                  type="submit"
                  value="Sign up"
                  className="btn btn-primary btn-block mt-4 lift"
                />
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Register.propTypes = {
  registerUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
});

export default connect(mapStateToProps, { registerUser })(withRouter(Register));
