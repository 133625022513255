import React from "react";
import { Link } from "react-router-dom";
import Icon from "../common/Icon";

function ToTop() {
  window.scrollTo(0, 0);
}

function Footer() {
  return (
    <div className="container">
      <footer className="sticky-bottom pt-4 my-md-4 pt-md-4 border-top">
        <div className="row">
          <div className="col-12 col-md">
            <Link to="/">
              <Icon className="p-0 d-inline" width="35" height="35"></Icon>
            </Link>

            <small className="d-block mb-3 text-muted">
              &copy; {new Date().getFullYear()}
            </small>
          </div>
          {/* <div className="col-6 col-md">
            <h5>Features</h5>
            <ul className="list-unstyled text-small">
              <li>
                <a className="text-muted" href="#">
                  Cool stuff
                </a>
              </li>
              <li>
                <a className="text-muted" href="#">
                  Random feature
                </a>
              </li>
              <li>
                <a className="text-muted" href="#">
                  Team feature
                </a>
              </li>
              <li>
                <a className="text-muted" href="#">
                  Stuff for developers
                </a>
              </li>
              <li>
                <a className="text-muted" href="#">
                  Another one
                </a>
              </li>
              <li>
                <a className="text-muted" href="#">
                  Last time
                </a>
              </li>
            </ul>
          </div> */}
          <div className="col-6 col-md">
            <h5>The Social Spoon</h5>
            <ul className="list-unstyled text-small">
              <li>
                <Link to="/landing">
                  <small className="text-muted">About</small>
                </Link>
              </li>
              <li>
                <Link to="/landing">
                  <small className="text-muted">Contact Us</small>
                </Link>
              </li>
              <li>
                <Link to="/sitemap.xml" target="_blank">
                  <small className="text-muted">Sitemap</small>
                </Link>
              </li>
              <li>
                <Link to={ToTop}>
                  <small className="text-muted">Scroll To Top</small>
                </Link>
              </li>
            </ul>
          </div>
          <div className="col-6 col-md">
            <h5>Legal</h5>
            <ul className="list-unstyled text-small">
              <li>
                <Link to="/legal">
                  <small className="text-muted">Terms of Use</small>
                </Link>
              </li>
              <li>
                <Link to="/legal">
                  <small className="text-muted">Privacy Policy</small>
                </Link>
              </li>
              <li>
                <Link to="/legal">
                  <small className="text-muted">Cookie Disclosure</small>
                </Link>
              </li>
              <li>
                <Link to="/legal">
                  <small className="text-muted">
                    Amazon Associates Disclosure
                  </small>
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </footer>
    </div>
  );

  // return (
  //   <footer className="sticky-bottom mt-5 p-4 text-center">
  //     <p className="float-left">
  //       {process.env.NODE_ENV === "production" && (
  //         <>
  //           <Link to="/legal">
  //             <span className="site-title">"(legal)"</span>
  //           </Link>

  //           <span className="site-title">
  //             &copy; {new Date().getFullYear()} The Social Spoon
  //           </span>
  //         </>
  //       )}

  //       {process.env.NODE_ENV !== "production" && (
  //         <>
  //           <span className="site-title">
  //             &copy; {new Date().getFullYear()} The Social Spoon{" "}
  //             <Link to="/legal">
  //               <small className="text-muted">(legal)</small>
  //             </Link>{" "}
  //             {Version()}
  //           </span>
  //         </>
  //       )}
  //     </p>

  //     <p className="float-right">
  //       <Link to="#" onClick={ToTop}>
  //         <small className="text-muted">Back to top</small>
  //       </Link>
  //     </p>
  //     {/* <small className="float-left text-muted mt-0 mb-2">
  //       {" "}
  //       The Social Spoon is a participant in the Amazon Services LLC Associates
  //       Program; We may get paid if you buy something or take an action after
  //       clicking our links.
  //     </small> */}
  //   </footer>
  // );
}

export default Footer;
