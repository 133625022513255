import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

class ShoppingCard extends Component {
  render() {
    //const { ingredients } = this.props;
    const ingredients = [
      {
        _id: "60397d2d0b2bc1d653b7f5c3",
        ingredient: {
          tags: [],
          related: [],
          equivalent: [],
          location: "PRODUCE",
          _id: "5ec73e009489ed20a6a1b07a",
          name: "tenderstem broccoli",
          url: "tenderstem-broccoli",
          created: "2021-02-26T22:58:48.742Z",
          author: "5d2bc042d4e3bb2ca9554daf",
          __v: 0,
        },
        amount: "1 1/2",
        unit: "lbs.",
      },
      {
        _id: "60397d2d0b2bc1d653b7f5c4",
        ingredient: {
          tags: [],
          related: [],
          equivalent: [],
          location: "BAKING",
          _id: "5e8fd9750685f4264891f448",
          name: "extra virgin olive oil",
          url: "extra-virgin-olive-oil",
          created: "2021-02-26T22:58:48.742Z",
          author: "5d2bc042d4e3bb2ca9554daf",
          __v: 0,
        },
        amount: "1/4",
        unit: "C.",
      },
      {
        _id: "60397d2d0b2bc1d653b7f5c5",
        ingredient: {
          tags: [],
          related: [],
          equivalent: [],
          location: "BAKING",
          _id: "5e8fd829724a0ad2d43a48f8",
          name: "kosher salt",
          url: "kosher-salt",
          created: "2021-02-26T22:58:48.741Z",
          author: "5d2bc042d4e3bb2ca9554daf",
          __v: 0,
        },
        amount: "2",
        unit: "tbsp.",
      },
      {
        _id: "60397d2d0b2bc1d653b7f5c6",
        ingredient: {
          tags: [],
          related: [],
          equivalent: [],
          location: "BAKING",
          _id: "5e8fda1f88fbe25cbb31f073",
          name: "ground black pepper",
          url: "ground-black-pepper",
          created: "2021-02-26T22:58:48.742Z",
          author: "5d2bc042d4e3bb2ca9554daf",
          __v: 0,
        },
        amount: "3",
        unit: "tbsp.",
      },
      {
        _id: "60397d2d0b2bc1d653b7f5c7",
        ingredient: {
          tags: [],
          related: [],
          equivalent: [],
          location: "DAIRY",
          _id: "5e8fd8345fb37af526ff1560",
          name: "sharp cheddar cheese",
          url: "sharp-cheddar-cheese",
          created: "2021-02-26T22:58:48.741Z",
          author: "5d2bc042d4e3bb2ca9554daf",
          __v: 0,
        },
        amount: "6",
        unit: "oz.",
      },
      {
        _id: "60397d2d0b2bc1d653b7f5c8",
        ingredient: {
          tags: [],
          related: [],
          equivalent: [],
          location: "PRODUCE",
          _id: "5ecac099e2df99deae903a34",
          name: "lemon",
          url: "lemon",
          plural: "lemons",
          created: "2021-02-26T22:58:48.743Z",
          author: "5d2bc042d4e3bb2ca9554daf",
          __v: 0,
        },
        amount: "1/2",
      },
    ];

    let ingredientsList = null;

    ingredientsList = ingredients.map((ingredient, index) => (
      <li className="list-group-item x-small text-muted">
        <Link
          key={ingredient._id}
          to={`/ingredient/${ingredient.ingredient.url}`}
        >
          {ingredient.ingredient.name}
        </Link>
      </li>
    ));

    return (
      <div className="card shadow-light-lg">
        <div className="bg-info px-4 py-1">
          <small className="mb-0 text-muted">Produce</small>
        </div>

        <ul className="list-group list-group-flush">{ingredientsList}</ul>
      </div>
    );
  }
}

ShoppingCard.defaultProps = {
  showActions: true,
};

ShoppingCard.propTypes = {
  recipe: PropTypes.object,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, {})(ShoppingCard);
