import React from "react";
import spinner from "./loading.svg";

export default function Spinner() {
  return (
    <div className="row justify-content-center my-5">
      <img src={spinner} style={{ width: "10%" }} alt="Loading" />
    </div>
  );
}
