import React, { Component } from "react";
import TextFieldGroup from "../common/TextFieldGroup";
import SelectListGroup from "../common/SelectListGroup";
import { Typeahead } from "react-bootstrap-typeahead";

class IngredientsForm extends Component {
  render() {
    //build measurement options
    var options = [];
    options.push({ label: "select...", value: "..." });
    options.push({ label: "teaspoons", value: "tsp." });
    options.push({ label: "tablespoons", value: "tbsp." });
    options.push({ label: "fluid ounces", value: "fl.oz." });
    options.push({ label: "cups", value: "C." });
    options.push({ label: "pints", value: "pt." });
    options.push({ label: "quarts", value: "qt." });
    options.push({ label: "gallons", value: "gal." });
    options.push({ label: "ounces", value: "oz." });
    options.push({ label: "pounds", value: "lbs." });
    options.push({ label: "grams", value: "g." });
    options.push({ label: "kilograms", value: "kg." });
    options.push({ label: "liters", value: "L." });
    options.push({ label: "mililiters", value: "mL." });

    let { ingredients, error } = this.props;

    const ingredientsListing = ingredients.map((ingredient, index) => (
      <li
        className="list-group-item d-flex align-items-center"
        key={ingredient.name}
      >
        <span className="badge badge-primary badge-pill">
          {ingredient.amount} {ingredient.unit}
        </span>
        <span className="ml-4">{ingredient.name}</span>
        <a href="#" onClick={(param) => this.props.onRemoveIngredient(index)}>
          <span className="ml-4 text-danger">
            <i className="fas fa-trash-alt"></i>
          </span>
        </a>
      </li>
    ));

    return (
      <div>
        <h4 className="mb-2">Ingredients</h4>
        {error && <div className="text-danger">{error}</div>}
        <ul className="list-group list-group-flush">{ingredientsListing}</ul>

        <div className="mt-2 form-row">
          <div className="form-group col-2">
            <TextFieldGroup
              placeholder="4"
              name="amount"
              label="Amount"
              onChange={this.props.onChange}
              value={this.props.amount}
            />
          </div>
          <div className="form-group col-4">
            <SelectListGroup
              placeholder="cups"
              name="unit"
              label="Measurement"
              onChange={this.props.onChange}
              options={options}
              value={this.props.unit}
            />
          </div>
          <div className="form-group col-6">
            <label htmlFor="ingredient">Ingredient</label>
            <Typeahead
              id="ingredient"
              name="ingredient"
              placeholder="all-purpose flour"
              options={this.props.options}
              labelKey="name"
              value={this.props.ingredient}
              onInputChange={this.props.onChangeTA}
              onChange={this.props.onChangeTA}
              allowNew={true}
              newSelectionPrefix="Add a new ingredient: "
            ></Typeahead>
          </div>
        </div>
        <div className="form-row form-group">
          <div className="col-4"></div>
          <div className="col-8">
            <button
              type="button"
              onClick={this.props.onAddIngredient}
              className="btn btn-primary float-right lift"
            >
              <span className="mr-3">Add Ingredient</span>
              <i className="fa fa-carrot text-warning" />
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default IngredientsForm;
